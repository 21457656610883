import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  CopilotActivityType,
  TestRun,
} from '@bloobirds-it/types';

import { BobjectTableQueryLogicRoles } from '../../../utils/bobjects.utils';

export const getProgressPercentage = (testRun: TestRun): string => {
  const results = Object.values(testRun?.results || {});

  const total = results.length;
  let processed = 0;

  results.forEach(value => {
    if (value) {
      processed++;
    }
  });
  const percentage = (processed / total) * 100;
  const roundedPercentage = Math.round(percentage);

  return isNaN(roundedPercentage) ? '0%' : `${roundedPercentage}%`;
};

export function getColumnsAndFiltersByActivityType(
  type: CopilotActivityType | 'All',
): { columns: string[]; filters: BobjectTableQueryLogicRoles[] } {
  switch (type) {
    case ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL:
    case 'Call':
      return {
        columns: [
          ACTIVITY_FIELDS_LOGIC_ROLE.DATETIME,
          ACTIVITY_FIELDS_LOGIC_ROLE.TYPE,
          ACTIVITY_FIELDS_LOGIC_ROLE.LEAD,
          ACTIVITY_FIELDS_LOGIC_ROLE.COMPANY,
          ACTIVITY_FIELDS_LOGIC_ROLE.CALL_RECORD_URL,
          ACTIVITY_FIELDS_LOGIC_ROLE.CALL_DURATION,
        ],
        filters: [
          {
            fieldLR: ACTIVITY_FIELDS_LOGIC_ROLE.TYPE,
            valuesLR: [ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL],
            searchMode: 'EXACT__SEARCH',
          },
          {
            fieldLR: ACTIVITY_FIELDS_LOGIC_ROLE.TRANSCRIPTION,
            valuesLR: '',
            searchMode: '__MATCH_FULL_ROWS__',
          },
        ],
      };

    case ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING:
    case 'Meeting':
      return {
        columns: [
          ACTIVITY_FIELDS_LOGIC_ROLE.DATETIME,
          ACTIVITY_FIELDS_LOGIC_ROLE.TYPE,
          ACTIVITY_FIELDS_LOGIC_ROLE.LEAD,
          ACTIVITY_FIELDS_LOGIC_ROLE.COMPANY,
          ACTIVITY_FIELDS_LOGIC_ROLE.CALL_RECORD_URL,
          ACTIVITY_FIELDS_LOGIC_ROLE.CALL_DURATION,
        ],
        filters: [
          {
            fieldLR: ACTIVITY_FIELDS_LOGIC_ROLE.TYPE,
            valuesLR: [ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING],
            searchMode: 'EXACT__SEARCH',
          },
          {
            fieldLR: ACTIVITY_FIELDS_LOGIC_ROLE.TRANSCRIPTION,
            valuesLR: '',
            searchMode: '__MATCH_FULL_ROWS__',
          },
        ],
      };

    case ACTIVITY_TYPES_VALUES_LOGIC_ROLE.LINKEDIN:
      return {
        columns: [
          ACTIVITY_FIELDS_LOGIC_ROLE.DATETIME,
          ACTIVITY_FIELDS_LOGIC_ROLE.TYPE,
          ACTIVITY_FIELDS_LOGIC_ROLE.LEAD,
          ACTIVITY_FIELDS_LOGIC_ROLE.COMPANY,
          ACTIVITY_FIELDS_LOGIC_ROLE.MESSAGE_SUBJECT,
          ACTIVITY_FIELDS_LOGIC_ROLE.MESSAGE_BODY,
        ],
        filters: [
          {
            fieldLR: ACTIVITY_FIELDS_LOGIC_ROLE.TYPE,
            valuesLR: [ACTIVITY_TYPES_VALUES_LOGIC_ROLE.LINKEDIN],
            searchMode: 'EXACT__SEARCH',
          },
        ],
      };

    case ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL:
    case 'Email':
      return {
        columns: [
          ACTIVITY_FIELDS_LOGIC_ROLE.DATETIME,
          ACTIVITY_FIELDS_LOGIC_ROLE.TYPE,
          ACTIVITY_FIELDS_LOGIC_ROLE.LEAD,
          ACTIVITY_FIELDS_LOGIC_ROLE.COMPANY,
          ACTIVITY_FIELDS_LOGIC_ROLE.MESSAGE_SUBJECT,
          ACTIVITY_FIELDS_LOGIC_ROLE.MESSAGE_BODY,
        ],
        filters: [
          {
            fieldLR: ACTIVITY_FIELDS_LOGIC_ROLE.TYPE,
            valuesLR: [ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL],
            searchMode: 'EXACT__SEARCH',
          },
        ],
      };

    default:
      return {
        columns: [
          ACTIVITY_FIELDS_LOGIC_ROLE.DATETIME,
          ACTIVITY_FIELDS_LOGIC_ROLE.TYPE,
          ACTIVITY_FIELDS_LOGIC_ROLE.LEAD,
          ACTIVITY_FIELDS_LOGIC_ROLE.COMPANY,
          ACTIVITY_FIELDS_LOGIC_ROLE.MESSAGE_SUBJECT,
          ACTIVITY_FIELDS_LOGIC_ROLE.MESSAGE_BODY,
          ACTIVITY_FIELDS_LOGIC_ROLE.CALL_RECORD_URL,
          ACTIVITY_FIELDS_LOGIC_ROLE.CALL_DURATION,
        ],
        filters: [
          {
            fieldLR: ACTIVITY_FIELDS_LOGIC_ROLE.TYPE,
            valuesLR: [
              ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL,
              ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL,
              ACTIVITY_TYPES_VALUES_LOGIC_ROLE.LINKEDIN,
              ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING,
            ],
            searchMode: 'EXACT__SEARCH',
          },
        ],
      };
  }
}

