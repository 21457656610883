import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Icon,
  IconButton,
  IconType,
  Label,
  Text,
  Tooltip,
} from '@bloobirds-it/flamingo-ui';
import { useTogglerState, useUserSettings } from '@bloobirds-it/hooks';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ActivityInsights,
  Bobject,
  BobjectId,
  BobjectTypes,
  CopilotInsight,
  CopilotObjectAnalysis,
  Insight,
  InsightType,
  MIXPANEL_EVENTS,
  MessagesEvents,
  PicklistField,
  UserType,
} from '@bloobirds-it/types';
import { api, getFieldByLogicRole, getTextFromLogicRole } from '@bloobirds-it/utils';
import { AnimatePresence, motion } from 'framer-motion';
import isNil from 'lodash/isNil';
import mixpanel from 'mixpanel-browser';
import useSWR, { mutate } from 'swr';

import { useUpdateInsights } from '../hooks';
import { useCopilotActivity } from '../hooks/useCopilotActivity';
import { useGetCoreInsights } from '../hooks/useGetInsights';
import { MarkdownReader } from '../markdownReader/markdownReader';
import {
  CollapsableBody,
  CollapsableHeader,
  InsightPlaceHolder,
  InsightStatusBadge,
} from './insightComponents';
import styles from './insights.module.css';

export const SectionTitle = ({
  activityId,
  title,
  category,
  copyValue,
  buttonIcon = 'noteAction',
  copyToNote,
  showCopyToNote = false,
  insight,
  showStatus,
  onOpenEmailModal,
  showEdit,
  onEdit,
  isEdit,
}: {
  activityId: BobjectId;
  title: string;
  category?: string;
  copyValue?: string;
  copyToNote?: (value: string) => void;
  buttonIcon?: IconType;
  showCopyToNote?: boolean;
  insight?: Insight;
  showStatus: boolean;
  onOpenEmailModal?: (text: string) => void;
  isEdit?: boolean;
  showEdit?: boolean;
  onEdit?: (mode: 'edit' | 'save' | 'cancel') => void;
}) => {
  const { t } = useTranslation();
  const [copyTooltip, setCopyTooltip] = useState(t('copyText.copyToClipboard'));
  const [copiedToNote, setCopiedToNote] = useState(false);
  const [loading, setLoading] = useState(false);
  const onCopy = () => {
    navigator.clipboard.writeText(copyValue).then(() => {
      setCopyTooltip(t('copyText.copied'));
      setTimeout(() => setCopyTooltip(t('copyText.copyToClipboard')), 1000);
    });
  };

  const onCopyToNote = () => {
    if (copyToNote) {
      setLoading(true);
      copyToNote(copyValue);
      setCopiedToNote(true);
      setLoading(false);
    } else {
      defaultCopyToNote();
    }
    mixpanel.track(MIXPANEL_EVENTS.CLICK_ON_ADD_NOTE_TO_INSIGHTS);
  };

  const defaultCopyToNote = () => {
    setLoading(true);

    api.get<Bobject>(`/bobjects/${activityId.value}/form`).then(res => {
      const freshActivity = res.data;
      const currentNote = getTextFromLogicRole(freshActivity, ACTIVITY_FIELDS_LOGIC_ROLE.NOTE);
      const finalNote = currentNote ? currentNote + `\n\n ${copyValue} ` : copyValue;
      const activityData = {
        [ACTIVITY_FIELDS_LOGIC_ROLE.NOTE]: finalNote,
      };
      api.patch(`/bobjects/${activityId.value}/raw`, activityData).then(() => {
        setLoading(false);
        setCopiedToNote(true);

        window.dispatchEvent(
          new CustomEvent(MessagesEvents.ActiveBobjectUpdated, {
            detail: { type: BobjectTypes.Activity },
          }),
        );
      });
    });
  };
  // in case isNil the status, we show regularly (to allow retrocompatibility)
  const canCopyToClipboard =
    copyValue && (isNil(insight?.status) || insight?.status === 'GENERATED') && !showStatus;

  const isFollowUp = category === 'FOLLOW_UP';

  return (
    <div className={styles.aiQuestionsTitle}>
      <div className={styles.titleSection}>
        <Icon name="stars" color="purple" />
        <Text size="s" weight="heavy">
          {title}
        </Text>
      </div>
      {canCopyToClipboard && (
        <div className={styles.titleSection}>
          {showEdit &&
            (!isEdit ? (
              <IconButton name="edit" color="purple" onClick={() => onEdit('edit')} size={16} />
            ) : (
              <>
                <IconButton
                  name="cross"
                  color="purple"
                  onClick={() => onEdit('cancel')}
                  size={16}
                />
                <IconButton name="check" color="purple" onClick={() => onEdit('save')} size={16} />
              </>
            ))}

          <Tooltip title={copyTooltip} position="top">
            <IconButton name="copy" color="purple" onClick={onCopy} size={16} />
          </Tooltip>
          {onOpenEmailModal && isFollowUp && (
            <Tooltip title={t('addToFollowUpEmail')} position="top">
              <IconButton
                name="deliver"
                color="purple"
                onClick={() => onOpenEmailModal(copyValue)}
                size={16}
              />
            </Tooltip>
          )}
          {showCopyToNote &&
            (!copiedToNote ? (
              <Tooltip
                title={t('activityTimelineItem.item.copilotInsights.addToInternalNote')}
                position="top"
              >
                <Button
                  size="small"
                  uppercase={false}
                  variant={!copiedToNote ? 'IAGradient' : 'clear'}
                  color={!copiedToNote ? undefined : 'extraCall'}
                  onClick={onCopyToNote}
                  disabled={loading}
                  className={styles.copyToNoteButton}
                >
                  <Icon
                    color={!copiedToNote ? 'purple' : 'extraCall'}
                    name={!copiedToNote ? buttonIcon : 'check'}
                    size={16}
                  ></Icon>
                  {t('activityTimelineItem.item.copilotInsights.addToNote')}
                </Button>
              </Tooltip>
            ) : (
              <div className={styles.copyToNoteButton}>
                <Icon color="extraCall" name="check" size={16}></Icon>
                <Text color="extraCall" size="xs">
                  {t('activityTimelineItem.item.copilotInsights.addedToNote')}
                </Text>
              </div>
            ))}
        </div>
      )}
      <InsightStatusBadge status={showStatus ? insight?.status : undefined} onlyBadge />
    </div>
  );
};

export const CopilotSummarySection = ({
  activityId,
  analysis,
  copyToNote,
  buttonIcon = 'noteAction',
  isInPreview,
  isLoading,
}: {
  activityId: BobjectId;
  analysis: ActivityInsights | CopilotObjectAnalysis;
  copyToNote?: (value: string) => void;
  buttonIcon?: IconType;
  isInPreview?: boolean;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();
  const [isOpen, toggle] = useTogglerState(true);
  const [isEdit, toggleEdit] = useTogglerState(false);
  const [summaryValue, setSummaryValue] = useState(analysis?.summary || '');

  const settings = useUserSettings();

  const isSupportUser = settings?.user.type === UserType.SUPPORT_USER;
  const { isTranscriptActivity } = useCopilotActivity();
  const { loadingUpdate, updateInsights } = useUpdateInsights({
    onSuccess: () => {
      mutate(`/copilot/objects/analysis-history/${activityId}`);
      toggleEdit();
    },
    successToast: 'ai.aiInsights.successfullUpdate',
    errorToast: 'ai.aiInsights.errorUpdate',
  });
  const previewStyle = {
    padding: 16,
    border: '1px solid #E2E5FF',
    borderRadius: '8px',
  };
  const onEdit = (mode: 'edit' | 'save' | 'cancel') => {
    if (!isEdit && mode === 'edit') {
      toggleEdit();
    } else {
      if (mode === 'cancel') {
        setSummaryValue(analysis?.summary);
        toggleEdit();
      }
      if (mode === 'save' && isEdit) {
        updateInsights(analysis, summaryValue);
      }
    }
  };
  useEffect(() => {
    if (!summaryValue && analysis.summary) {
      setSummaryValue(analysis.summary);
    }
  }, [analysis]);

  return (
    <div className={styles.aiQuestions} style={isInPreview ? previewStyle : undefined}>
      <CollapsableHeader toggle={toggle} isOpen={isOpen}>
        <SectionTitle
          activityId={activityId}
          title={
            t('activityTimelineItem.item.copilotInsights.aiGeneratedNote') +
            (loadingUpdate ? '  ✨ Updating ✨' : '')
          }
          copyValue={analysis?.summary}
          copyToNote={copyToNote}
          buttonIcon={buttonIcon}
          showCopyToNote={isTranscriptActivity}
          showStatus={!isOpen}
          showEdit={isSupportUser && 'object_type' in analysis}
          onEdit={onEdit}
          isEdit={isEdit}
        />
      </CollapsableHeader>
      <CollapsableBody isOpen={isOpen}>
        {!isLoading ? (
          !isEdit ? (
            <MarkdownReader text={summaryValue || ''} />
          ) : (
            <textarea
              className={styles.summaryTextArea}
              value={summaryValue}
              onChange={e => setSummaryValue(e.target.value)}
            />
          )
        ) : (
          <div className={styles.loading}>
            <Label color="purple-100" textColor="purple-400" uppercase={false}>
              {t('common.loading')}
            </Label>
          </div>
        )}
      </CollapsableBody>
    </div>
  );
};

export const TagGroup = ({ values }: { values: { text: string; active: boolean }[] }) => {
  const activeStyle = {
    backgroundColor: '#1126EA',
    color: '#FFFFFF',
  };
  const [showMore, toggle] = useTogglerState(false);
  const [isAnimating, toggleIsAnimating] = useTogglerState(false);

  const { t } = useTranslation();

  const activeKeywords = [];
  const inactiveKeywords = [];

  values.forEach(value => {
    value.active ? activeKeywords.push(value) : inactiveKeywords.push(value);
  });

  return (
    <div className={styles.tagGroup}>
      {activeKeywords.map(value => (
        <div className={styles.tagContainer} key={value.text}>
          <p className={styles.tag} style={activeStyle} title={value.text}>
            {value.text}
          </p>
        </div>
      ))}
      {inactiveKeywords.slice(0, 3).map(value => (
        <div className={styles.tagContainer} key={value.text}>
          <p className={styles.tag} title={value.text}>
            {value.text}
          </p>
        </div>
      ))}
      {inactiveKeywords.length > 3 && !showMore && !isAnimating && (
        <Button
          className={styles._showMore_button}
          variant="clear"
          size="small"
          color="purple"
          uppercase={false}
          onClick={toggle}
        >
          {t('common.showAll')}
        </Button>
      )}
      <AnimatePresence
        onExitComplete={() => {
          toggleIsAnimating();
        }}
      >
        {showMore &&
          inactiveKeywords.slice(3).map((value, index) => (
            <motion.div
              key={value.text}
              className={styles.tagContainer}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{
                opacity: 0,
                y: -20,
                transition: {
                  duration: 0.3,
                  delay: (inactiveKeywords.length - 1 - index) * 0.1,
                },
              }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              <p className={styles.tag} title={value.text}>
                {value.text}
              </p>
            </motion.div>
          ))}
        {inactiveKeywords.length > 3 && showMore && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{
              opacity: 0,
              y: -20,
              transition: {
                duration: 0.3,
                delay: (inactiveKeywords.length - 1) * 0.1,
              },
            }}
            transition={{ duration: 0.3, delay: (inactiveKeywords.length - 1) * 0.1 }}
          >
            <Button
              className={styles._showMore_button}
              variant="clear"
              size="small"
              color="purple"
              uppercase={false}
              onClick={() => {
                toggleIsAnimating();
                toggle();
              }}
            >
              {t('common.hide')}
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

interface InsightProps {
  bobjectId: BobjectId;
  insight: Insight;
  insightDefinition: CopilotInsight;
  onOpenEmailModal?: (text: string) => void;
  analysis: ActivityInsights | CopilotObjectAnalysis;
}

export const DecisionInsight = ({ bobjectId, insight, insightDefinition }: InsightProps) => {
  const { data: picklistField } = useSWR<PicklistField>(
    `/utils/picklists/${insightDefinition?.activityField}/type`,
    key => api.get<PicklistField>(key).then(res => res.data),
  );
  const [isOpen, toggle] = useTogglerState(
    isNil(insight?.status) || insight?.status === 'GENERATED',
  );
  const values = picklistField?.values.map(pv => ({
    text: pv.value,
    active: insight?.choices?.includes(pv.id),
  }));

  return (
    <div className={styles.aiQuestions}>
      <CollapsableHeader toggle={toggle} isOpen={isOpen}>
        <SectionTitle
          showStatus={!isOpen}
          title={insightDefinition?.title}
          activityId={bobjectId}
          insight={insight}
        />
      </CollapsableHeader>
      <CollapsableBody isOpen={isOpen}>
        {insight?.status && insight?.status !== 'GENERATED' && (
          <div className={styles.versionInsightRow}>
            <InsightStatusBadge status={insight?.status} />
          </div>
        )}
        {isNil(insight?.status) || insight.status === 'GENERATED' ? (
          values && <TagGroup values={values} />
        ) : (
          <InsightPlaceHolder status={insight?.status} />
        )}
      </CollapsableBody>
    </div>
  );
};

export const GenerationInsight = ({
  bobjectId,
  insight,
  insightDefinition,
  onOpenEmailModal,
  analysis,
}: InsightProps) => {
  const [isOpen, toggle] = useTogglerState(
    isNil(insight?.status) || insight?.status === 'GENERATED',
  );

  const [isEdit, toggleEdit] = useTogglerState(false);
  const [generatedText, setGeneratedText] = useState(insight?.generated_text || '');
  const settings = useUserSettings();
  const isSupportUser = settings?.user.type === UserType.SUPPORT_USER;
  const { loadingUpdate, updateInsights } = useUpdateInsights({
    onSuccess: () => {
      mutate(`/copilot/objects/analysis-history/${insight?.activityId}`);
      toggleEdit();
    },
    successToast: 'ai.aiInsights.successfullUpdate',
    errorToast: 'ai.aiInsights.errorUpdate',
  });

  const onEdit = (mode: 'edit' | 'save' | 'cancel') => {
    if (!isEdit && mode === 'edit') {
      toggleEdit();
    } else {
      if (mode === 'cancel') {
        setGeneratedText(analysis?.summary);
        toggleEdit();
      }
      if (mode === 'save' && isEdit) {
        updateInsights(analysis, generatedText, insight?.insightId);
      }
    }
  };

  return (
    <div className={styles.aiQuestions}>
      <CollapsableHeader toggle={toggle} isOpen={isOpen}>
        <SectionTitle
          title={insightDefinition?.title + (loadingUpdate ? '  ✨ Updating ✨' : '')}
          category={insightDefinition?.category}
          activityId={bobjectId}
          copyValue={insight?.generated_text}
          showStatus={!isOpen}
          insight={insight}
          onOpenEmailModal={onOpenEmailModal}
          showEdit={isSupportUser && 'object_type' in analysis}
          onEdit={onEdit}
          isEdit={isEdit}
        />
      </CollapsableHeader>
      <CollapsableBody isOpen={isOpen}>
        {insight?.status && insight?.status !== 'GENERATED' && (
          <div className={styles.versionInsightRow}>
            <InsightStatusBadge status={insight?.status} />
          </div>
        )}
        {isNil(insight?.status) || insight.status === 'GENERATED' ? (
          !isEdit ? (
            <div className={styles.markdown}>
              <MarkdownReader text={generatedText || ''} />
            </div>
          ) : (
            <textarea
              className={styles.summaryTextArea}
              value={generatedText}
              onChange={e => setGeneratedText(e.target.value)}
            />
          )
        ) : (
          <InsightPlaceHolder status={insight?.status} />
        )}
      </CollapsableBody>
    </div>
  );
};

const insightComponents: Record<InsightType, (props: InsightProps) => JSX.Element> = {
  DECISION: DecisionInsight,
  GENERATION: GenerationInsight,
};

interface InsightRendererProps {
  insight: Insight;
  insightsList: CopilotInsight[];
  bobjectId: BobjectId;
  onOpenEmailModal?: (text: string) => void;
  analysis: ActivityInsights | CopilotObjectAnalysis;
}

export const InsightRenderer = ({
  insight,
  insightsList,
  bobjectId,
  onOpenEmailModal,
  analysis,
}: InsightRendererProps) => {
  const Insight = insightComponents[insight.insight_type];
  const coreInsight = insightsList?.find(
    ci => ci.id === insight.insightId && ci.objectType === bobjectId.typeName,
  );

  if (!coreInsight || !Insight) {
    return null;
  }

  return (
    <Insight
      bobjectId={bobjectId}
      insight={insight}
      insightDefinition={coreInsight}
      onOpenEmailModal={onOpenEmailModal}
      analysis={analysis}
    />
  );
};

interface CopilotAnalysisProps {
  activity: Bobject;
  onEdit?: () => void;
  onOpenEmailModal?: (text: string) => void;
}

export const CopilotAnalysis = ({ activity, onEdit, onOpenEmailModal }: CopilotAnalysisProps) => {
  const { t } = useTranslation();
  const { activityInsights, setOverlay } = useCopilotActivity();
  const type = getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE);

  const mainTypeField = getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_MAIN_TYPE);
  const coreInsights = useGetCoreInsights({ type, mainTypeField });
  const nonQualityInsights = [...coreInsights.values()].filter(ci => ci.category !== 'QA');
  const goBack = () => {
    setOverlay(undefined);
    if (onEdit) {
      onEdit();
    }
  };

  return (
    <>
      {activityInsights && (
        <div className={styles.insightsContainer}>
          <div
            style={{ display: 'flex', alignItems: 'center', gap: '4px', cursor: 'pointer' }}
            onClick={goBack}
          >
            <Icon name="arrowLeft" />
            <Text size="xs" color="bloobirds">
              {t('activityTimelineItem.item.copilotInsights.backTo')}
            </Text>
          </div>
          <CopilotSummarySection analysis={activityInsights} activityId={activity.id} />
          {nonQualityInsights &&
            activityInsights?.insights?.map(i => {
              return (
                <InsightRenderer
                  key={i.pk}
                  analysis={activityInsights}
                  bobjectId={activity?.id}
                  insight={i}
                  onOpenEmailModal={onOpenEmailModal}
                  insightsList={nonQualityInsights}
                />
              );
            })}
        </div>
      )}
    </>
  );
};
