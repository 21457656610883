import {
  BobjectFromSobject,
  BobjectId,
  BobjectTypes,
  OPPORTUNITY_FIELDS_LOGIC_ROLE,
} from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';

export const getRelatedTypeFromComposedId = (relatedObjectId: string | string[]): string => {
  if (!relatedObjectId) return null;
  const relatedId = typeof relatedObjectId === 'string' ? relatedObjectId : relatedObjectId?.[0];
  return relatedId?.split('/')?.[1];
};

export const getBobjectTypeFromComposedId = (relatedObjectId: string | string[]): BobjectTypes => {
  if (!relatedObjectId) return null;
  const relatedId = typeof relatedObjectId === 'string' ? relatedObjectId : relatedObjectId?.[0];
  const sobjectType = relatedId?.split('/')?.[0];
  return BobjectFromSobject[sobjectType];
};
