import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Text } from '@bloobirds-it/flamingo-ui';
import {
  useActiveAccountId,
  useActivityUserMentionsEnabled,
  useUserMentions,
} from '@bloobirds-it/hooks';
import {
  deserialize,
  EditorToolbar,
  EditorToolbarControlsSection,
  EditorToolbarFontStylesSection,
  EditorToolbarListsSection,
  EditorToolbarTextMarksSection,
  isEmptyText,
  RichTextEditor,
  serialize,
  useRichTextEditorPlugins,
} from '@bloobirds-it/rich-text-editor';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_MAIN_NOTE_VALUES_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  BobjectTypes,
  COMPANY_FIELDS_LOGIC_ROLE,
  LEAD_FIELDS_LOGIC_ROLE,
  MainBobjectTypes,
  MessagesEvents,
  OPPORTUNITY_FIELDS_LOGIC_ROLE,
} from '@bloobirds-it/types';
import { api, createParagraph, isHtml } from '@bloobirds-it/utils';
import { focusEditor, getEndPoint } from '@udecode/plate';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import debounce from 'lodash/debounce';

import { getMentionsFromNote } from '../../utils/notes.utils';
import styles from './noteEditor.module.css';

const mainNoteField = {
  [BobjectTypes.Company]: COMPANY_FIELDS_LOGIC_ROLE.MAIN_NOTE,
  [BobjectTypes.Lead]: LEAD_FIELDS_LOGIC_ROLE.MAIN_NOTE,
  [BobjectTypes.Opportunity]: OPPORTUNITY_FIELDS_LOGIC_ROLE.MAIN_NOTE,
};

interface NoteEditorProps {
  id: string;
  relatedId?: string;
  activeRelatedId?: string;
  data: string;
  openFloatingNote: boolean;
  expand?: boolean;
  collapse?: boolean;
  setData: (data: string) => void;
  enableBlur?: boolean;
  hoverColor?: string;
  placeholder?: string;
  isMainNote?: boolean;
}

interface IContentEditor {
  id: string;
  relatedId?: string;
  t: TFunction;
  data: string;
  setData: (data: string) => void;
  setExitModeInline: () => void;
  plugins: any;
  users: any;
  expand: boolean;
  enableBlur?: boolean;
  placeholder?: string;
  isMainNote?: boolean;
  activeRelatedId?: string;
}

const NoteToolbar = React.memo(() => (
  <div className={styles.toolbar}>
    {/* @ts-ignore */}
    <EditorToolbar backgroundColor="var(--peanut) !important">
      <EditorToolbarControlsSection color="peanut" />
      <EditorToolbarFontStylesSection color="peanut" />
      <EditorToolbarTextMarksSection color="peanut" />
      <EditorToolbarListsSection color="peanut" />
    </EditorToolbar>
  </div>
));

const getDeserialize = (message, plugins) => {
  if (message) {
    return typeof message === 'string'
      ? isHtml(message)
        ? deserialize(message, {
            format: 'HTML',
            plugins: plugins,
          })
        : createParagraph(message)
      : message;
  }

  return null;
};

const getSerialize = (message, plugins) => {
  if (message) {
    return serialize(message, {
      format: 'AST',
      plugins: plugins,
    });
  }
  return null;
};

const updateMainNoteInRelated = (related: string, relatedBobjectType: string, value?: string) => {
  if (related && relatedBobjectType) {
    api.patch(`/bobjects/${related}/raw`, {
      contents: {
        [mainNoteField[relatedBobjectType]]: value ? [value] : null,
      },
      params: { duplicateValidation: true },
    });
  }
};

const NoteContentEditor = ({
  id,
  relatedId,
  activeRelatedId,
  t,
  data,
  setData,
  setExitModeInline,
  plugins,
  users,
  expand,
  enableBlur = false,
  placeholder,
  isMainNote,
}: IContentEditor) => {
  const [content, setContent] = useState(data);
  const accountId = useActiveAccountId();
  const internalId = useRef(id);
  const bodyEditor = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (bodyEditor.current) {
        const endPoint = getEndPoint(bodyEditor.current, []);
        focusEditor(bodyEditor.current, endPoint);
      }
    }, 100);
  }, [bodyEditor.current]);

  useEffect(() => {
    if (typeof content !== 'string') {
      const dataSerialized = getSerialize(content, plugins);
      setData(dataSerialized);
    }
  }, [content]);

  const handleSubmit = (content, relatedObjectId) => {
    const dataSerialized = getSerialize(content, plugins);
    const mentions = getMentionsFromNote(content);
    const contentSerialized = {
      [ACTIVITY_FIELDS_LOGIC_ROLE.NOTE]: dataSerialized,
      [ACTIVITY_FIELDS_LOGIC_ROLE.MENTIONS]: mentions,
    };

    if (dataSerialized !== data) {
      const params = { duplicateValidation: true };
      try {
        if (internalId.current) {
          api
            .patch(`/bobjects/${internalId.current}/raw`, {
              contents: { ...contentSerialized },
              params,
            })
            .then(() => {
              window.dispatchEvent(
                new CustomEvent(MessagesEvents.ActiveBobjectUpdated, {
                  detail: { type: BobjectTypes.Activity },
                }),
              );
            });
        } else {
          const dataToCreate = {
            ...contentSerialized,
            [ACTIVITY_FIELDS_LOGIC_ROLE.TYPE]: ACTIVITY_TYPES_VALUES_LOGIC_ROLE.NOTE,
            [ACTIVITY_FIELDS_LOGIC_ROLE.MAIN_NOTE]: isMainNote
              ? ACTIVITY_MAIN_NOTE_VALUES_LOGIC_ROLE.YES
              : ACTIVITY_MAIN_NOTE_VALUES_LOGIC_ROLE.NO,
            ...(activeRelatedId
              ? { [ACTIVITY_FIELDS_LOGIC_ROLE.RELATED_OBJECT_ID]: activeRelatedId }
              : {}),
          };

          let relatedBobjectType: MainBobjectTypes;
          if (relatedObjectId) {
            relatedBobjectType = Object.keys(BobjectTypes).find(type =>
              relatedObjectId?.includes(type),
            ) as MainBobjectTypes;

            const roles = {
              Lead: 'LEAD',
              Company: 'COMPANY',
              Opportunity: 'OPPORTUNITY',
            };
            Object.keys(roles).forEach(role => {
              dataToCreate[ACTIVITY_FIELDS_LOGIC_ROLE[roles[role]]] = relatedObjectId.includes(role)
                ? relatedObjectId
                : null;
            });
          }

          api
            .post(`/bobjects/${accountId}/Activity`, {
              contents: { ...dataToCreate },
              params,
            })
            .then(response => {
              internalId.current = response?.data?.value;
              if (isMainNote && !activeRelatedId) {
                updateMainNoteInRelated(relatedId, relatedBobjectType, response?.data?.value);
              }

              window.dispatchEvent(
                new CustomEvent(MessagesEvents.ActiveBobjectUpdated, {
                  detail: { type: BobjectTypes.Activity },
                }),
              );
            });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const debouncedSubmit = useCallback(debounce(handleSubmit, 1000), [relatedId]);

  const contentDeserialize = getDeserialize(content, plugins);

  const editorClasses = clsx(styles.editor, {
    [styles.expandEditor]: expand,
  });

  const handleMouseLeave = () => {
    if (!enableBlur) {
      setExitModeInline();
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (e.relatedTarget && e.currentTarget.contains(e.relatedTarget)) {
      return;
    }
    if (enableBlur) {
      setExitModeInline();
    }
  };

  return (
    <div className={styles.contentEditor} onMouseLeave={handleMouseLeave} onBlur={handleBlur}>
      <RichTextEditor
        id={(activeRelatedId || relatedId) + '-mainNote'}
        defaultValue={contentDeserialize}
        plugins={plugins}
        users={users?.users}
        placeholder={placeholder || t('internalNotePlaceholder')}
        onChange={text => {
          setContent(text);
          debouncedSubmit(text, relatedId);
        }}
        setEditor={editor => {
          bodyEditor.current = editor;
        }}
        style={{
          color: 'var(--peanut) !important',
          padding: '4px',
        }}
      >
        {editor => (
          <>
            <div className={editorClasses}>{editor}</div>
            <div>
              <NoteToolbar />
            </div>
          </>
        )}
      </RichTextEditor>
    </div>
  );
};

const ShowMoreButton = ({ showMore, t, onClick }) => (
  <Button
    className={styles.showMoreButton}
    size="small"
    color="bloobirds"
    variant="clear"
    iconRight={showMore ? 'chevronUp' : 'chevronDown'}
    uppercase={false}
    onClick={onClick}
  >
    {t(showMore ? 'showLess' : 'showMore')}
  </Button>
);

export const NoteEditor = ({
  id,
  relatedId,
  activeRelatedId,
  data,
  openFloatingNote,
  expand = false,
  collapse = false,
  setData,
  enableBlur = true,
  hoverColor = '#f9f8fb',
  placeholder,
  isMainNote,
}: NoteEditorProps) => {
  const [isModeInline, setModeInline] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'extension.noteModal' });
  const [showMore, setShowMore] = useState(false);
  const users = useUserMentions();
  const accountId = useActiveAccountId();
  const mentionsEnabled = useActivityUserMentionsEnabled(accountId);

  const plugins = useRichTextEditorPlugins({
    images: false,
    replaceParagraphs: true,
    userMentions: mentionsEnabled,
    elements: true,
  });

  useEffect(() => {
    if (openFloatingNote) setModeInline(false);
  }, [openFloatingNote]);

  const classes = clsx(styles.htmlText, {
    [styles.floatingNote]: openFloatingNote,
    [styles.noData]: !data,
    [styles.expand]: expand,
    [styles.collapse]: collapse && !showMore,
  });

  const editorClasses = clsx(styles.htmlText, {
    [styles.expand]: expand,
    [styles.collapse]: collapse && !showMore,
  });

  if ((!data || isEmptyText(data, plugins)) && !isModeInline) {
    return (
      <div
        onClick={() => setModeInline(true)}
        className={classes}
        // @ts-ignore
        style={{ '--hover-color': hoverColor, color: 'var(--softPeanut)' }}
      >
        {placeholder || t('internalNotePlaceholder')}
      </div>
    );
  }

  if (isModeInline) {
    return (
      <NoteContentEditor
        id={id}
        relatedId={relatedId}
        activeRelatedId={activeRelatedId}
        t={t}
        data={data}
        setData={setData}
        users={users}
        plugins={plugins}
        setExitModeInline={() => {
          setModeInline(false);

          if (isMainNote && !activeRelatedId) {
            setTimeout(() => {
              window.dispatchEvent(new CustomEvent(MessagesEvents.UpdateContext));
            }, 500);
          }
        }}
        expand={expand}
        enableBlur={enableBlur}
        placeholder={placeholder}
        isMainNote={isMainNote}
      />
    );
  }

  const TextContainer = () => {
    if (isHtml(data)) {
      return (
        <div
          // @ts-ignore
          style={{ '--hover-color': hoverColor }}
          className={editorClasses}
          dangerouslySetInnerHTML={{ __html: data }}
          onClick={() => setModeInline(true)}
        />
      );
    } else {
      return (
        // @ts-ignore
        <div style={{ '--hover-color': hoverColor }} onClick={() => setModeInline(true)}>
          <Text className={editorClasses} size="xs">
            {data ? data : 'None'}
          </Text>
        </div>
      );
    }
  };

  return (
    <div className={styles.box}>
      <TextContainer />
      {collapse && (
        <ShowMoreButton showMore={showMore} t={t} onClick={() => setShowMore(!showMore)} />
      )}
    </div>
  );
};
