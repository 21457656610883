import { IUsePromiseHandlerOptions, useLoadingPromise } from '@bloobirds-it/hooks';
import { ActivityInsights, CopilotObjectAnalysis } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';

interface IUseUpdateInsights {
  updateInsights: (
    analysis: ActivityInsights | CopilotObjectAnalysis,
    newValue: string,
    insightId?: string,
  ) => Promise<void>;
  loadingUpdate: boolean;
}
export const useUpdateInsights = (
  options: IUsePromiseHandlerOptions<any> = {},
): IUseUpdateInsights => {
  const { loading, promiseHandler } = useLoadingPromise();
  const updateAnalysis = (
    analysis: ActivityInsights | CopilotObjectAnalysis,
    newValue: string,
    insightId?: string,
  ) => {
    if (!insightId) {
      //if we dont have insightId, its the summary that we are modifying;
      analysis.summary = newValue;
    } else {
      analysis.insights = analysis.insights.map(ins => {
        if (ins.insightId === insightId) {
          ins.generated_text = newValue;
        }
        return ins;
      });
    }
    //update endpoint
    return api
      .patch<
        ActivityInsights | CopilotObjectAnalysis
      >(`/copilot/objects/${analysis.pk}`, { summary: analysis?.summary, insights: analysis?.insights })
      .then(response => response?.data);
  };

  const updateInsights = (
    analysis: ActivityInsights | CopilotObjectAnalysis,
    newValue: string,
    insightId?: string,
  ) => promiseHandler(() => updateAnalysis(analysis, newValue, insightId), options);

  return { updateInsights, loadingUpdate: loading };
};
