import React, { useEffect, useRef, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BobjectSelector } from '@bloobirds-it/bobjects';
import { CopilotSummarySection } from '@bloobirds-it/copilot';
import { Button, Icon, Spinner, Text, useToasts } from '@bloobirds-it/flamingo-ui';
import {
  useActivityUserMentionsEnabled,
  useCopilotEnabled,
  useDebouncedCallback,
} from '@bloobirds-it/hooks';
import {
  deserialize,
  EditorToolbar,
  EditorToolbarControlsSection,
  EditorToolbarFontStylesSection,
  EditorToolbarListsSection,
  EditorToolbarTextMarksSection,
  MyEditor,
  RichTextEditor,
  serialize,
  useRichTextEditorPlugins,
} from '@bloobirds-it/rich-text-editor';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_MAIN_NOTE_VALUES_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  ActivityInsights,
  BobjectId,
} from '@bloobirds-it/types';
import { api, isHtml, createParagraph } from '@bloobirds-it/utils';
import { insertNodes, removeNodes, TComboboxItemBase } from '@udecode/plate';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import useSWR from 'swr';

import { getMentionsFromNote } from '../../utils/notes.utils';
import styles from './noteForm.module.css';
import { onBobjectChange } from './noteForm.utils';

const defaultNewNoteValueTitle = (t: TFunction) => [
  {
    type: 'h2',
    children: [
      {
        text: t('newNote'),
      },
    ],
  },
];

export interface NoteFormProps {
  activityId: BobjectId;
  title?: string;
  content?: any;
  users?: TComboboxItemBase[];
  related?: string;
  relatedName?: string;
  accountId: string;
  onSave?: (id?: string) => void;
  bodyPlaceholder?: string;
  activityType?: string;
  alternativeFooter?: JSX.Element;
  showFooter?: boolean;
  fitAllHeight?: boolean;
  copilotAnalysis?: string;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NoteForm = (props: NoteFormProps) => {
  const {
    activityId,
    title: noteTitle,
    content: noteContent,
    users,
    related,
    relatedName,
    accountId,
    onSave,
    bodyPlaceholder,
    activityType,
    alternativeFooter,
    showFooter = true,
    fitAllHeight,
    setIsLoading,
    copilotAnalysis,
  } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'notes' });
  const mentionsEnabled = useActivityUserMentionsEnabled(accountId);

  const { control, getValues, handleSubmit, watch } = useForm();
  const [nameSelected, setNameSelected] = useState<string>(relatedName);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const isEditionModal = useRef(!!activityId);

  const isNote =
    activityType !== ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING &&
    activityType !== ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL;

  const isCall = activityType === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL;
  const isMeeting = activityType === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING;

  const isAnActivityFieldNote = isMeeting || isCall;

  const { createToast } = useToasts();
  const [isSubmitting, setIsSubmitting] = useState<boolean>();

  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(isSubmitting);
    }
  }, [isSubmitting]);

  const titlePlugins = useRichTextEditorPlugins({
    singleLine: true,
  });

  const plugins = useRichTextEditorPlugins({
    images: false,
    replaceParagraphs: true,
    userMentions: mentionsEnabled,
    elements: true,
  });

  function getTitle() {
    if (isHtml(noteTitle)) {
      if (noteTitle && typeof noteTitle === 'string') {
        return deserialize(noteTitle, {
          format: 'HTML',
          plugins: titlePlugins,
        });
      } else {
        return defaultNewNoteValueTitle(t);
      }
    } else {
      if (noteTitle && typeof noteTitle === 'string') {
        return !isNote ? noteTitle : createParagraph(noteTitle);
      } else {
        return defaultNewNoteValueTitle(t);
      }
    }
  }

  const defaultValues = {
    title: getTitle(),
    body:
      noteContent && typeof noteContent === 'string'
        ? isHtml(noteContent)
          ? deserialize(noteContent, {
              format: 'HTML',
              plugins: plugins,
            })
          : createParagraph(noteContent)
        : null,
  };

  const {
    field: { value: title, onChange: titleOnChange },
  } = useController({
    control,
    name: ACTIVITY_FIELDS_LOGIC_ROLE.TITLE,
    defaultValue: defaultValues.title,
  });

  const {
    field: { value: note, onChange: noteOnChange },
  } = useController({
    control,
    name: ACTIVITY_FIELDS_LOGIC_ROLE.NOTE,
    defaultValue: defaultValues.body,
  });

  const {
    field: { onChange: relatedOnChange },
  } = useController({
    control,
    name: 'related',
    defaultValue: related,
  });

  const titleValue = watch(ACTIVITY_FIELDS_LOGIC_ROLE.TITLE);
  const noteValue = watch(ACTIVITY_FIELDS_LOGIC_ROLE.NOTE);

  useEffect(() => {
    if (!hasChanges && (titleValue || noteValue)) {
      if (
        JSON.stringify(defaultValues.title) !== JSON.stringify(titleValue) ||
        JSON.stringify(defaultValues.body) !== JSON.stringify(noteValue)
      ) {
        setHasChanges(true);
      }
    }
  }, [titleValue, noteValue]);

  const onSubmit = () => {
    setIsSubmitting(true);
    const { related, ...rest } = getValues();
    const mentions = getMentionsFromNote(rest[ACTIVITY_FIELDS_LOGIC_ROLE.NOTE]);
    const dataToCreate = {
      [ACTIVITY_FIELDS_LOGIC_ROLE.NOTE]: rest[ACTIVITY_FIELDS_LOGIC_ROLE.NOTE]
        ? serialize(rest[ACTIVITY_FIELDS_LOGIC_ROLE.NOTE], {
            format: 'AST',
            plugins,
          })
        : null,
    };
    if (isNote) {
      dataToCreate[ACTIVITY_FIELDS_LOGIC_ROLE.TITLE] = rest[ACTIVITY_FIELDS_LOGIC_ROLE.TITLE]
        ? serialize(rest[ACTIVITY_FIELDS_LOGIC_ROLE.TITLE], {
            format: 'AST',
            plugins,
          })
        : null;

      dataToCreate[ACTIVITY_FIELDS_LOGIC_ROLE.MAIN_NOTE] = ACTIVITY_MAIN_NOTE_VALUES_LOGIC_ROLE.NO;
      dataToCreate[ACTIVITY_FIELDS_LOGIC_ROLE.TYPE] = ACTIVITY_TYPES_VALUES_LOGIC_ROLE.NOTE;
    }
    if (related && isNote) {
      const roles = {
        Lead: 'LEAD',
        Company: 'COMPANY',
        Opportunity: 'OPPORTUNITY',
      };
      Object.keys(roles).forEach(role => {
        dataToCreate[ACTIVITY_FIELDS_LOGIC_ROLE[roles[role]]] = related.includes(role)
          ? related
          : null;
      });
    }

    const params = { duplicateValidation: true };
    if (!isEditionModal.current) {
      api
        .post(`/bobjects/${accountId}/Activity`, {
          contents: { ...dataToCreate },
          ...(mentions?.length > 0 ? { mentions } : {}),
          params,
        })
        .then(activityCreated => {
          setIsSubmitting(false);
          onSave?.(activityCreated?.data?.value);
        })
        .catch(() => {
          setIsSubmitting(false);
          createToast({ message: t('toasts.errorCreating'), type: 'error' });
        });
    } else {
      api
        .patch(`/bobjects/${activityId?.value}/raw`, {
          contents: dataToCreate,
          ...(mentions?.length > 0 ? { mentions } : {}),
          params: {},
        })
        .then(() => {
          setIsSubmitting(false);
          onSave?.(activityId?.value);
        })
        .catch(() => {
          setIsSubmitting(false);
          createToast({ message: t('toasts.errorUpdating'), type: 'error' });
        });
    }
  };

  const saveNote = useDebouncedCallback(
    () => {
      onSubmit();
      isEditionModal.current = true;
    },
    500,
    [onSubmit],
  );

  useEffect(() => {
    if (hasChanges) {
      saveNote();
    }
  }, [JSON.stringify(noteValue)]);

  const alternativeFooterComponent = alternativeFooter
    ? React.cloneElement(alternativeFooter, {
        onSubmit: handleSubmit(onSubmit),
      })
    : null;

  const containerClass = clsx(styles.container, {
    [styles.fullHeight]: fitAllHeight,
  });

  function getBackgroundColor() {
    if (isMeeting) return 'var(--lightestMeeting)';
    if (isCall) return 'var(--lightestCall)';
    return 'var(--banana)';
  }

  const isCopilotEnabled = useCopilotEnabled(accountId);

  const hasAi = !!copilotAnalysis;

  const { data: activityInsights } = useSWR(
    hasAi && `/copilot/transcript/insights/${activityId.objectId}`,
    key => api.get<ActivityInsights>(key).then(res => res.data),
  );

  const copyToNote = (value: string) => {
    if (noteValue.length === 1 && noteValue[0].children[0].text === '') {
      removeNodes(bodyEditor, { at: [0] });
      insertNodes(bodyEditor, createParagraph(value)?.[0], { at: [0] });
    } else {
      removeNodes(bodyEditor, { at: [0] });
      insertNodes(bodyEditor, [...noteValue, ...createParagraph(''), ...createParagraph(value)], {
        at: [0],
      });
    }
  };

  const [bodyEditor, setBodyEditor] = useState<MyEditor>(null);

  return (
    <div className={containerClass}>
      <div className={styles.detail_content_container}>
        <div className={styles.editor}>
          <span className={styles.title_container}>
            {!isCall && (
              <div
                className={styles.noteIcon}
                style={{
                  backgroundColor: getBackgroundColor(),
                }}
              >
                {isMeeting && <Icon name="calendar" color="extraMeeting" size={16} />}
                {isNote && <Icon name="noteAction" color="peanut" size={16} />}
              </div>
            )}
            {isAnActivityFieldNote ? (
              <Text weight="bold" className={styles.title}>
                {noteTitle}
              </Text>
            ) : (
              <RichTextEditor
                id={'note-detail-title'}
                defaultValue={title}
                placeholder={t('titlePlaceholder')}
                plugins={titlePlugins}
                onChange={titleOnChange}
                style={{
                  padding: '4px 12px',
                }}
              />
            )}
            {isSubmitting && (
              <div className={styles.isSavingContainer}>
                <Spinner size={11} name="loadingCircle" color="softPeanut" />
                <Text size="xs" color="softPeanut">
                  {t('saving')}
                </Text>
              </div>
            )}
          </span>
          {isCopilotEnabled && activityInsights && (
            <CopilotSummarySection
              analysis={activityInsights}
              activityId={activityId}
              buttonIcon="arrowDown"
              copyToNote={copyToNote}
              isInPreview={true}
            />
          )}
          {!isCopilotEnabled || (!activityInsights && <span className={styles.divider} />)}
          <RichTextEditor
            id={'note-detail-body'}
            defaultValue={note}
            setEditor={setBodyEditor}
            plugins={plugins}
            placeholder={bodyPlaceholder || t('placeholder')}
            onChange={noteOnChange}
            users={users}
            style={{
              padding: '12px 28px 4px 28px',
            }}
          >
            {editor => (
              <>
                <div className={styles.body_wrapper}>{editor}</div>
                <div className={styles.toolbar}>
                  {/* @ts-ignore */}
                  <EditorToolbar backgroundColor="var(--peanut) !important">
                    <EditorToolbarControlsSection color="peanut" />
                    <EditorToolbarFontStylesSection color="peanut" />
                    <EditorToolbarTextMarksSection color="peanut" />
                    <EditorToolbarListsSection color="peanut" />
                  </EditorToolbar>
                </div>
              </>
            )}
          </RichTextEditor>
        </div>
        {showFooter && (
          <div>
            <div
              className={clsx(styles.bottom_bar, {
                [styles.bottom_bar_alternative]: !!alternativeFooter,
              })}
            >
              <span className={styles.record_related}>
                {isNote && (
                  <div className={styles.bobject_selector}>
                    <BobjectSelector
                      accountId={accountId}
                      selected={nameSelected}
                      id={activityId?.value}
                      onBobjectChange={bobject =>
                        onBobjectChange(bobject, relatedOnChange, setNameSelected)
                      }
                    />
                  </div>
                )}
              </span>
              {alternativeFooter ? (
                alternativeFooterComponent
              ) : (
                <span>
                  <Button
                    size="small"
                    onClick={() => {
                      handleSubmit(onSubmit)();
                    }}
                    disabled={isSubmitting || !hasChanges}
                  >
                    {isSubmitting ? <Spinner name="loadingCircle" size={12} /> : t('save')}
                  </Button>
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
