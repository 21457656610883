import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text, Button } from '@bloobirds-it/flamingo-ui';

import { usePhoneNumberFormContext } from '../../context/phoneNumberFormProvider';
import styles from '../../styles/phoneNumberPage.module.css';
import { InfoCallout } from './customCallouts';
import { RadioGroupRobin } from './radioGroupRobin';

export const CallDistribution = () => {
  const { t } = useTranslation();
  const { phoneNumber, handleChange, handleSaveChanges, hasChanges } = usePhoneNumberFormContext();

  return (
    <div className={styles.callDistributionContainer}>
      <div className={styles.itemInformation}>
        <Text size="s" color="softPeanut" weight="regular">
          {t(
            'accountSettings.phoneNumbers.phoneSettings.tabs.advancedSettings.sectionCallDistribution.subtitle',
          )}
        </Text>
      </div>
      <RadioGroupRobin phone={phoneNumber} handleChange={handleChange} />
      <div>
        <InfoCallout />
      </div>
      <div>
        <Button
          disabled={!hasChanges()}
          variant="primary"
          size="medium"
          onClick={() => handleSaveChanges(phoneNumber)}
        >
          {t('common.save')}
        </Button>
      </div>
    </div>
  );
};
