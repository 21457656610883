import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Text } from '@bloobirds-it/flamingo-ui';

import { PhoneNumberConfiguration } from '../components/generalSettings/phoneNumberConfiguration';
import { PhoneNumberInformation } from '../components/generalSettings/phoneNumberInformation';
import { usePhoneNumberFormContext } from '../context/phoneNumberFormProvider';
import styles from '../styles/phoneNumberPage.module.css';

export const GeneralSettings = () => {
  const { t } = useTranslation();
  const { phoneNumber } = usePhoneNumberFormContext();

  return (
    <div className={styles.generalSettingsContainer}>
      <div>
        <Text className={styles.titleGeneral} size="l" color="peanut" weight="medium">
          <Icon name="info" size={24} color="bloobirds" />
          {t('accountSettings.phoneNumbers.phoneSettings.tabs.generalSettings.generalInfo')}
        </Text>
        <PhoneNumberInformation phone={phoneNumber} />
      </div>
      <div className={styles.itemGeneral}>
        <Text className={styles.titleGeneral} size="l" color="peanut" weight="medium">
          <Icon name="settings" size={24} color="bloobirds" />
          {t('accountSettings.phoneNumbers.phoneSettings.tabs.generalSettings.numberConfiguration')}
        </Text>
        <PhoneNumberConfiguration />
      </div>
    </div>
  );
};
