import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  CheckItem,
  Item,
  MultiSelect,
  Select,
  Spinner,
  Switch,
  Text,
} from '@bloobirds-it/flamingo-ui';
import {
  useFullSalesEnabled,
  useIsNoStatusPlanAccount,
  usePlaybookSegmentation,
  useUserSettings,
  useUserTeams,
} from '@bloobirds-it/hooks';
import {
  TEMPLATE_FORMS_ROUTES,
  TEMPLATE_TYPES,
  TemplateStage,
  UserPermission,
} from '@bloobirds-it/types';
import { baseUrls } from '@bloobirds-it/utils';
import clsx from 'clsx';

import { DuplicateMetaTemplate } from '../../playbookFilters/components/duplicateMetaTemplate';
import { SendToMetaBlock } from '../../playbookFilters/components/sendToMetaBlock';
import styles from './segmentationForm.module.css';

const Title = ({ title }: { title: string }) => {
  return (
    <Text weight="bold" size="s">
      {title}
    </Text>
  );
};

const SubTitle = ({ text }: { text: string }) => {
  return (
    <Text color="softPeanut" size="xs">
      {text}
    </Text>
  );
};

const SwitchRow = ({
  text,
  field,
  disabled = false,
}: {
  text: string;
  field: { value; onChange };
  disabled?: boolean;
}) => {
  return (
    <div className={styles.row}>
      <Switch
        color="purple"
        checked={field.value}
        onChange={bool => field.onChange(bool)}
        disabled={disabled}
      />
      <Text size="s" weight="medium">
        {text}
      </Text>
    </div>
  );
};

const SegmentationFieldsByStage = ({
  segmentationFields,
  segmentationField: { value: selectedSegmentation, onChange: setSelectedSegmentation },
  stage,
  withTitle = false,
}: {
  segmentationFields;
  segmentationField;
  stage: TemplateStage;
  withTitle?: boolean;
}) => {
  const { t } = useTranslation();
  if (!segmentationFields[stage] || segmentationFields[stage].length === 0) {
    return null;
  }

  return (
    <div className={clsx(styles.sectionContent, { [styles.withoutTitle]: !withTitle })}>
      {withTitle && (
        <Text size="xs" color="softPeanut">
          {t(`playbook.segmentationFilter.${stage.toLowerCase()}`)}
        </Text>
      )}
      {segmentationFields[stage]?.map(segmentation => {
        const selectedValues = selectedSegmentation[stage]?.[segmentation.id];
        const onChange = (values: any[]) => {
          setSelectedSegmentation({
            ...selectedSegmentation,
            [stage]: {
              ...selectedSegmentation[stage],
              ...(values.length && { [segmentation.id]: values }),
            },
          });
        };
        const renderValue = (values: string[]) => {
          if (values && values.length) {
            const selectedNames = values.map(id => segmentation.values.find(v => v.id === id).name);
            if (selectedNames.length === segmentation.values.length) {
              return t('common.allValuesSelected');
            } else if (selectedNames.length === 1) {
              return selectedNames[0];
            } else {
              return (
                selectedNames.length +
                t('common.selected')?.toLowerCase() +
                ': ' +
                selectedNames?.join(', ')
              );
            }
          } else {
            return t('common.select') + ' ' + segmentation.name;
          }
        };
        return (
          <MultiSelect
            key={segmentation.id}
            size="small"
            value={selectedValues ? selectedValues : []}
            width="100%"
            placeholder={t('common.select') + ' ' + segmentation.name}
            renderDisplayValue={renderValue}
            onChange={onChange}
            selectAllOption
            autocomplete
          >
            {segmentation.values?.map(value => (
              <CheckItem
                key={value.id}
                value={value.id}
                label={value.name}
                checked={selectedValues?.includes(value.id)}
              >
                {value.name}
              </CheckItem>
            ))}
          </MultiSelect>
        );
      })}
    </div>
  );
};

const SegmentationFields = ({
  stage,
  ...props
}: {
  segmentationField;
  segmentationFields;
  stage: TemplateStage;
}) => {
  const stages =
    stage === TemplateStage.All ? [TemplateStage.Prospecting, TemplateStage.Sales] : [stage];
  const isNoStatusPlanAccount = useIsNoStatusPlanAccount();

  return (
    <>
      {stages.map(s => (
        <SegmentationFieldsByStage
          key={'SegmentationFieldsByStage' + s}
          stage={s}
          withTitle={stage === TemplateStage.All && !isNoStatusPlanAccount}
          {...props}
        />
      ))}
    </>
  );
};

const SegmentationTeams = ({
  isLoadingTeams,
  teamList,
  teamsField,
  visibleField,
}: {
  isLoadingTeams: boolean;
  teamList: any;
  teamsField: any;
  visibleField: string;
}) => {
  const handleTeamsChange = teamsField.onChange;
  const { t } = useTranslation('translation', { keyPrefix: 'playbook.segmentationFilter' });

  return (
    <MultiSelect
      width="100%"
      disabled={isLoadingTeams || visibleField === 'PRIVATE'}
      sortByChecked={false}
      placeholder={t('allTeams')}
      autocomplete={teamList?.length > 8}
      selectAllOption
      defaultValue={teamsField.value.map(team => team.id)}
      onChange={handleTeamsChange}
    >
      {isLoadingTeams ? (
        <Spinner size={16} name="loadingCircle" />
      ) : (
        teamList?.map(team => (
          <CheckItem key={team.id} value={team.id} label={team.name}>
            {team.name}
          </CheckItem>
        ))
      )}
    </MultiSelect>
  );
};

export const SegmentationForm = ({
  canBeBattlecard,
  isMetaSent,
  templateInfo,
}: {
  canBeBattlecard: boolean;
  isMetaSent?: boolean;
  templateInfo?: {
    templateName: string;
    templateId: string;
    templateType: TEMPLATE_TYPES;
  };
}) => {
  const { control } = useFormContext();
  const settings = useUserSettings();
  const accountId = settings?.account?.id;
  const isFullSalesEnabled = useFullSalesEnabled(accountId);
  const isNoStatusPlanAccount = useIsNoStatusPlanAccount();
  const hasSendingMetaPermissions = settings?.user?.permissions?.includes(
    UserPermission.WHATSAPP_BUSINESS_TEMPLATE_ADMIN,
  );
  const isAdmin = settings?.user?.accountAdmin;
  const { teams, isLoadingTeams, isAdminOrManager } = useUserTeams();

  const {
    field: { ref: stageRef, ...stageField },
  } = useController({
    control,
    name: 'stage',
    defaultValue: isNoStatusPlanAccount ? TemplateStage.All : TemplateStage.Prospecting,
  });

  const { field: teamsField } = useController({
    control,
    name: 'teams',
    defaultValue: [],
  });

  const { segmentationFields } = usePlaybookSegmentation(stageField.value);

  const { t } = useTranslation('translation', { keyPrefix: 'playbook.segmentationFilter' });

  const { field: visibleField } = useController({ control, name: 'visibility' });
  const { field: officialField } = useController({ control, name: 'isOfficial' });
  const { field: battlecardField } = useController({ control, name: 'isBattlecard' });
  const { field: segmentationField } = useController({ control, name: 'segmentationValues' });
  const visibleFieldFunctions = {
    value: visibleField.value === 'PUBLIC',
    onChange: b => visibleField.onChange(b ? 'PUBLIC' : 'PRIVATE'),
  };

  const renderStage = (stage: TemplateStage) => {
    switch (stage) {
      case TemplateStage.All:
        return t('prospectAndSalesStages');
      case TemplateStage.Prospecting:
        return t('prospectStage');
      case TemplateStage.Sales:
        return t('salesStage');
    }
  };

  const duplicateAction = () => {
    const baseUrl = baseUrls[process.env.NODE_ENV];

    const url = `${baseUrl}${TEMPLATE_FORMS_ROUTES[templateInfo?.templateType]}?mode=CLONE&id=${
      templateInfo?.templateId
    }`;
    window.open(url, '_blank');
  };

  const showSegmentation =
    segmentationFields &&
    ((stageField.value === TemplateStage.All &&
      (segmentationFields[TemplateStage.Prospecting]?.length > 0 ||
        segmentationFields[TemplateStage.Sales]?.length > 0)) ||
      (stageField.value !== TemplateStage.All && segmentationFields[stageField.value]?.length > 0));

  return (
    <div className={styles.container}>
      {hasSendingMetaPermissions &&
        templateInfo?.templateType === TEMPLATE_TYPES.WHATSAPP &&
        (isMetaSent ? (
          <DuplicateMetaTemplate
            size="small"
            duplicateAction={duplicateAction}
            templateName={templateInfo?.templateName}
          />
        ) : (
          <SendToMetaBlock size="small" />
        ))}
      {(!isNoStatusPlanAccount || !isFullSalesEnabled) && (
        <div className={styles.section}>
          <Title title={t('stage')} />
          <Select
            size="small"
            placeholder={t('stage')}
            {...stageField}
            width={'100%'}
            borderless={false}
            renderDisplayValue={renderStage}
          >
            <Item value={TemplateStage.All}>{t('all')}</Item>
            <Item value={TemplateStage.Prospecting}>{t('prospectStage')}</Item>
            <Item value={TemplateStage.Sales}>{t('salesStage')}</Item>
          </Select>
        </div>
      )}
      <div className={styles.section}>
        <Title title={t('options')} />
        <SubTitle text={t('canChooseMoreThanOne')} />
        <div className={styles.sectionContent}>
          <SwitchRow text={t('visibleToAllMembers')} field={visibleFieldFunctions} />
          <SwitchRow text={t('officialPlaybook')} field={officialField} disabled={!isAdmin} />
          {canBeBattlecard && <SwitchRow text={t('playbookBattlecard')} field={battlecardField} />}
        </div>
      </div>
      {isAdminOrManager && (
        <div className={styles.section}>
          <Title title={t('segmentateTeam')} />
          <SubTitle text={t('subtitleSegmentateTeam')} />
          <div className={styles.sectionContent}>
            <SegmentationTeams
              teamList={teams}
              isLoadingTeams={isLoadingTeams}
              teamsField={teamsField}
              visibleField={visibleField.value}
            />
          </div>
        </div>
      )}
      {showSegmentation && (
        <div className={styles.section}>
          <Title title={t('categorization')} />
          {stageField.value !== TemplateStage.All && <SubTitle text={t('categorizationText')} />}
          {segmentationFields && (
            <SegmentationFields
              segmentationFields={segmentationFields}
              segmentationField={segmentationField}
              stage={stageField.value}
            />
          )}
        </div>
      )}
    </div>
  );
};
