import React, { MutableRefObject } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import {
  Dropdown,
  Icon,
  IconType,
  Item,
  Select,
  Spinner,
  Text,
  useVisible,
} from '@bloobirds-it/flamingo-ui';
import { Bobject, FIELDS_LOGIC_ROLE } from '@bloobirds-it/types';
import { getTextFromLogicRole } from '@bloobirds-it/utils';

import { useStatusNoteActionContext } from '../../hooks/useStatusNoteActions';

export const ColumnHeader = ({
  icon,
  text,
  loading,
  children,
}: {
  icon: IconType;
  text: string | React.ReactElement;
  loading?: boolean;
  children?: React.ReactNode;
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginBottom: '8px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <Icon name={icon} color="softPeanut" />
          <Text size="m" color="peanut">
            {text}
          </Text>
        </div>
        {loading ? <Spinner name="loadingCircle" size={16} color="softPeanut" /> : children}
      </div>
    </div>
  );
};
