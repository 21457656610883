import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createToast } from '@bloobirds-it/flamingo-ui';

export interface IUsePromiseHandlerOptions<T = any> {
  onSuccess?: (result: T) => void;
  onError?: (error: any) => void;
  onFinally?: () => void;
  successToast?: string;
  errorToast?: string;
}

export const useLoadingPromise = <T = any,>() => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const promiseHandler = useCallback(
    async (promise: () => Promise<T>, options: IUsePromiseHandlerOptions<T> = {}) => {
      const { onSuccess, onError, onFinally, successToast, errorToast } = options;

      setLoading(true);

      try {
        const result = await promise();
        onSuccess?.(result);
        if (successToast) {
          createToast({
            type: 'success',
            message: t(successToast),
          });
        }
      } catch (error) {
        onError?.(error);
        if (!onError) {
          console.error(error);
        }
        if (errorToast) {
          createToast({
            type: 'error',
            message: t(errorToast),
          });
        }
      } finally {
        setLoading(false);
        onFinally?.();
      }
    },
    [],
  );

  return { promiseHandler, loading };
};
