import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useCountriesList = () => {
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const { i18n } = useTranslation();

  const lang = i18n.language;

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        setIsLoading(true);
        const response = await fetch('https://restcountries.com/v3.1/all');

        if (!response.ok) {
          throw new Error('Failed to fetch countries');
        }

        const data = await response.json();

        const countriesWithFlags = data
          .filter(country => country.idd?.root)
          .map(country => {
            // Calcular el emoji de la bandera una vez
            const flagEmoji = String.fromCodePoint(
              ...country.cca2
                .toUpperCase()
                .split('')
                .map(char => 127397 + char.charCodeAt(0)),
            );

            return {
              ...country,
              flagEmoji,
            };
          })
          .sort((a, b) => a.name.common.localeCompare(b.name.common));

        setCountries(countriesWithFlags);
        setIsLoading(false);
      } catch (err) {
        setError('Error fetching countries');
        setIsLoading(false);
        console.error(err);
      }
    };

    fetchCountries();
  }, []);

  const formatCountryOption = country => {
    if (lang === 'en') {
      return `${country.flagEmoji} ${country.name.common}`;
    } else if (lang === 'es') {
      return `${country.flagEmoji} ${country.translations.spa.common}`;
    }
  };

  return {
    countries,
    isLoading,
    error,
    formatCountryOption,
  };
};
