import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Text } from '@bloobirds-it/flamingo-ui';
import { useConfetti, useUserHelpers, useUserSettings } from '@bloobirds-it/hooks';
import { APP_TASKS_WELCOME, MIXPANEL_EVENTS, UserHelperKeys } from '@bloobirds-it/types';
import mixpanel from 'mixpanel-browser';

import { useRouter } from '../../../../../hooks';
import styles from './otoSlides.module.css';

export const LastScreenOTO = () => {
  const { save } = useUserHelpers();
  const { throwConfetti } = useConfetti();
  const { t } = useTranslation('translation', { keyPrefix: 'welcomeScreens.otoSlides.lastScreen' });

  // TODO Remove this after Revolut onboarding, checking if account is Revolut or Tofu
  const settings = useUserSettings();
  const router = useRouter();
  const isRevolut =
    settings?.account?.id === '1ukQPEbwsnOxNfVs' || settings?.account?.id === '7VA3TbSzLkrOE3Ud';

  useEffect(() => {
    mixpanel.track(MIXPANEL_EVENTS.DISPLAY_LAST_PAGE_WELCOME_SCREEN_OTO);
    const timeout = setTimeout(() => {
      save(UserHelperKeys.COMPLETE_WELCOME_SCREEN);
    }, 15000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div key="lastScreen" className={styles.backgroundWhite}>
      <div className={styles.lastScreenWrapper}>
        <Text size="xxxl" align="center" weight="bold" color="peanut">
          <Trans
            i18nKey="welcomeScreens.otoSlides.lastScreen.title"
            components={[
              <Text key="0" size="xxxl" weight="heavy" inline color="bloobirds">
                {''}
              </Text>,
            ]}
          />
          <span
            style={{ cursor: 'default' }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              throwConfetti({ bloobirdsShape: true });
            }}
          >
            {' '}
            🎉
          </span>
        </Text>
        <div className={styles.lastScreenButtons}>
          <Button
            size="medium"
            iconRight="bloobirds"
            onClick={() => {
              if (isRevolut) router.push(APP_TASKS_WELCOME);
              save(UserHelperKeys.COMPLETE_WELCOME_SCREEN);
            }}
          >
            {t('startBloobirds')}
          </Button>
        </div>
      </div>
    </div>
  );
};
