export const headerNames = [
  {
    label: '',
  },
  {
    label: 'Salesforce status',
  },
  {
    label: 'Category',
  },
  {
    label: 'Record type',
  },
  {
    label: 'Preview',
  },
  {
    label: 'Active',
  },
];

export const crmObjects = [
  {
    label: 'Lead',
    crmObject: 'Lead',
    icon: 'personBody',
  },
  {
    label: 'Contact',
    crmObject: 'Contact',
    icon: 'sfdcContacts',
  },
  {
    label: 'Account',
    crmObject: 'Account',
    icon: 'company',
  },
  {
    label: 'Person account',
    crmObject: 'Account',
    icon: 'person',
  },
  {
    label: 'Opportunity',
    crmObject: 'Opportunity',
    icon: 'sfdcOpp',
  },
];
