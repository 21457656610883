import { MIXPANEL_EVENTS, SentryMessages, SentryTags } from '@bloobirds-it/types';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';

interface GeneralAnalytics {
  data?: any;
  response?: any;
  error?: any;
  extra?: Record<string, any>;
}

interface SendMixpanelEventI extends GeneralAnalytics {
  key: MIXPANEL_EVENTS;
}

interface SendSentryErrorI extends GeneralAnalytics {
  sentryMessage: SentryMessages | string;
  sentryTag: SentryTags;
}

interface ThirdPartyAnalyticsTryCatchI extends SendMixpanelEventI, SendSentryErrorI {
  action: () => void;
}

export const useThirdPartyAnalytics = () => {
  const sendMixpanelErrorEvent = ({ key, error, data, extra }: SendMixpanelEventI) => {
    mixpanel.track(key, {
      ...(error ? { Error: JSON.stringify(error || {}) } : {}),
      ...(data ? { Data: JSON.stringify(data || {}) } : {}),
      ...(extra ? extra : {}),
    });
  };

  const sendSentryErrorEvent = ({
    sentryMessage,
    sentryTag,
    response,
    data,
    error,
    extra,
  }: SendSentryErrorI) => {
    Sentry.captureException(new Error(sentryMessage), {
      tags: {
        module: sentryTag,
      },
      extra: {
        ...(response ? { response: JSON.stringify(response) || {} } : {}),
        ...(data ? { data: JSON.stringify(data) || {} } : {}),
        ...(error ? { error: JSON.stringify(error) || {} } : {}),
        ...(extra ? extra : {}),
      },
    });
  };

  const sendThirdPartyError = (
    mixpanelProps: { key: MIXPANEL_EVENTS },
    sentryProps: { sentryMessage: SentryMessages | string; sentryTag: SentryTags },
    props: GeneralAnalytics,
  ) => {
    sendMixpanelErrorEvent({ ...mixpanelProps, ...props });
    sendSentryErrorEvent({ ...sentryProps, ...props });
  };

  const thirdPartyAnalyticsTryCatch = ({
    action,
    key,
    sentryMessage,
    sentryTag,
    data,
    extra,
  }: ThirdPartyAnalyticsTryCatchI) => {
    try {
      action();
    } catch (error) {
      sendMixpanelErrorEvent({ key, error, data, extra });
      sendSentryErrorEvent({ sentryMessage, sentryTag, data, error });
    }
  };

  return {
    sendThirdPartyError,
    sendMixpanelErrorEvent,
    sendSentryErrorEvent,
    thirdPartyAnalyticsTryCatch,
  };
};
