import { useEntity } from './entities/useEntity';

export const usePicklistValues = ({ picklistLogicRole }: { picklistLogicRole: string }) => {
  const fields = useEntity('bobjectFields');
  const values = useEntity('bobjectPicklistFieldValues');

  if (!fields || !values) {
    return [];
  }

  return values.filterBy('bobjectField', fields.findByLogicRole(picklistLogicRole)?.id);
};

export const useGlobalPicklistValues = ({ logicRole }: { logicRole: string }) => {
  const bobjectGlobalPicklist = useEntity('bobjectGlobalPicklists');
  const values = useEntity('bobjectPicklistFieldValues');

  if (!bobjectGlobalPicklist || !values) {
    return [];
  }
  const globalPicklist = bobjectGlobalPicklist?.findByLogicRole(logicRole);
  return values.filterBy('bobjectGlobalPicklist', globalPicklist?.id);
};
