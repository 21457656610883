import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Spinner,
  Text,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import { Bobject, MIXPANEL_EVENTS } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import mixpanel from 'mixpanel-browser';

import styles from './cancelEmailModal.module.css';

const CancelEmailModal = ({
  handleClose,
  onSubmit,
  open,
  bobject,
}: {
  handleClose: () => void;
  onSubmit: () => void;
  open: boolean;
  bobject: Bobject;
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { createToast } = useToasts();
  const isBulkAction = Array.isArray(bobject);
  const { t } = useTranslation('translation', {
    keyPrefix: 'smartEmailModal.components.cancelEmailModal',
  });

  const cancelEmail = () => {
    setIsSubmitting(true);
    mixpanel.track(MIXPANEL_EVENTS.EMAIL_SCHEDULED_CANCELED_FROM_MODAL);
    api
      .delete(`/bobjects/${bobject?.id?.value}`, {
        headers: { 'Content-Type': 'application/json' },
        data: {},
      })
      .then(() => {
        mixpanel.track(MIXPANEL_EVENTS.EMAIL_SCHEDULED_CANCELED_SUCCESSFULLY);

        handleClose();
        if (onSubmit) {
          onSubmit();
        }
        setIsSubmitting(false);
        createToast({
          message: t('toasts.success'),
          type: 'success',
        });
      })
      .catch(e => {
        mixpanel.track(MIXPANEL_EVENTS.EMAIL_SCHEDULED_CANCELED_FAILED, {
          Error: JSON.stringify(e),
        });
        handleClose();
        setIsSubmitting(false);
        createToast({
          message: t('toasts.error'),
          type: 'error',
        });
      });
  };

  return (
    <Modal open={open} onClose={handleClose} width={640}>
      <ModalHeader>
        <ModalTitle>Cancel email</ModalTitle>
        <ModalCloseIcon onClick={handleClose} />
      </ModalHeader>
      <ModalContent>
        <div className={styles.text}>
          {!isBulkAction ? (
            <Text size="m" align="center">
              {t('title')}
              <br />
              <b>{t('subtitle')}</b>
            </Text>
          ) : (
            <Text size="m" align="center">
              {t('titleBulk', { count: bobject?.length || 0 })}
              <br />
              <b>{t('subtitle')}</b>
            </Text>
          )}
        </div>
      </ModalContent>
      <ModalFooter>
        <Button variant="clear" onClick={handleClose}>
          {t('back')}
        </Button>
        <Button color="tomato" onClick={cancelEmail}>
          {isSubmitting ? (
            <Spinner color="white" size={14} name="loadingCircle" />
          ) : (
            t('cancelEmail')
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CancelEmailModal;
