import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActivityTimelineItem } from '@bloobirds-it/activity-timeline-item';
import { OpenClickedStatistics } from '@bloobirds-it/bobjects';
import { CopilotActivityContextProvider } from '@bloobirds-it/copilot';
import { Button, Icon, Spinner, Text, Timeline } from '@bloobirds-it/flamingo-ui';
import {
  useBaseSetEmailVariablesValues,
  useEmailActivities,
  useMinimizableModals,
  usePermissions,
  useSyncBobjectStatus,
  useUserSettings,
} from '@bloobirds-it/hooks';
import { LightAttachmentList } from '@bloobirds-it/light-attachment-list';
import { serialize } from '@bloobirds-it/rich-text-editor';
import {
  ACTIVITY_DIRECTION,
  ACTIVITY_FIELDS_LOGIC_ROLE,
  Bobject,
  BobjectId,
  BobjectTypes,
  DataModelResponse,
  LEAD_FIELDS_LOGIC_ROLE,
  MessagesEvents,
  MIXPANEL_EVENTS,
  UserPermission,
} from '@bloobirds-it/types';
import {
  api,
  EMAIL_MODE,
  getFieldByLogicRole,
  getReferencedBobject,
  getTextFromLogicRole,
  getValueFromLogicRole,
  isHtml,
  parseEmailPixels,
  removeHtmlTags,
} from '@bloobirds-it/utils';
import mixpanel from 'mixpanel-browser';

import { ActivityWithIAWrapper } from '../../components/activityWithAIWrapper/activityWithAIWrapper';
import { DetailsFooter } from '../../components/detailsFooter/detailsFooter';
import { useEmailThreadActivities } from '../../hooks';
import styles from './emailThreadDetail.module.css';

const openHtmlNewTab = async activityId => {
  const response = await api.get(`/utils/service/activity/${activityId}/body`);
  const blob = new Blob([response.data], { type: 'text/html' });
  const url = URL.createObjectURL(blob);
  window.open(url, '_blank');
};

const EmailThreadDetailedActivity = ({
  activity,
  fetchBody,
}: {
  activity: Bobject;
  fetchBody: (id: string) => Promise<string>;
}) => {
  const [loading, setLoading] = useState(false);
  const [activityBody, setActivityBody] = useState('');
  const [isBodyTooLarge, setIsBodyTooLarge] = useState(false);

  const fetchActivityBody = useCallback(async () => {
    if (!activityBody) {
      try {
        const miniBody = getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.MESSAGE_BODY);
        if (miniBody && miniBody.includes('Content too long to display')) {
          setIsBodyTooLarge(true);
          setActivityBody(miniBody);
          return;
        }
        let body = await fetchBody(activity?.id?.objectId);
        if (body && isHtml(body)) {
          body = parseEmailPixels(body.replace(/<style>[\s\S]*?<\/style>/g, ''));
        }
        setActivityBody(body);
      } catch (error) {
        console.error('Failed to fetch activity body:', error);
      }
    }
  }, [activity?.id?.objectId, activityBody, fetchBody]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchActivityBody();
    }
    return () => {
      isMounted = false;
    };
  }, [fetchActivityBody]);

  const isHtmlMessage = useMemo(() => activityBody && isHtml(activityBody), [activityBody]);

  const activityAttachedFiles = useMemo(
    () => getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.ATTACHED_FILES),
    [activity],
  );

  const activityAttachments = useMemo(
    () => getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.ATTACHMENTS),
    [activity],
  );

  const isIncomingActivity = useMemo(
    () =>
      getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.DIRECTION) ===
      ACTIVITY_DIRECTION.INCOMING,
    [activity],
  );

  const parsedAttachedFiles = useMemo(
    () => activityAttachedFiles && JSON.parse(activityAttachedFiles),
    [activityAttachedFiles],
  );

  const parsedAttachments = useMemo(
    () => activityAttachments && JSON.parse(activityAttachments),
    [activityAttachments],
  );

  return (
    <div className={styles.htmlBody}>
      {!isBodyTooLarge ? (
        isHtmlMessage ? (
          <div dangerouslySetInnerHTML={{ __html: activityBody }} />
        ) : (
          <div className={styles._text_message}>
            <Text size="xs">{activityBody}</Text>
          </div>
        )
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Text size="xs">The message body is too large to be displayed.</Text>
          <Button
            uppercase={false}
            size="small"
            variant="tertiary"
            onClick={async e => {
              e.preventDefault();
              e.stopPropagation();
              setLoading(true);
              await openHtmlNewTab(activity.id?.objectId);
              setLoading(false);
            }}
          >
            {!loading ? (
              'Click here to open in new tab'
            ) : (
              <div className={styles.loading}>
                <Spinner name="loadingCircle" />
              </div>
            )}
          </Button>
        </div>
      )}
      {parsedAttachedFiles && parsedAttachedFiles?.length !== 0 && (
        <LightAttachmentList files={parsedAttachedFiles} betterAttachments={parsedAttachments} />
      )}
      {!isIncomingActivity && (
        <div className={styles.openedClickedElement}>
          <OpenClickedStatistics bobject={activity} size="small" />
        </div>
      )}
    </div>
  );
};
interface IEmailThreadDetailProps {
  activity: Bobject;
  emailThreadId: string;
  accountId: string;
  dataModel: DataModelResponse;
  visibleFooter?: boolean;
  actionsDisabled?: boolean;
  userId?: string;
  deleteActivityCallback?: (deletedActivityId: string, isSingleEmail: boolean) => void;
  readOnly?: boolean;
}

export const EmailThreadDetail = ({
  activity,
  emailThreadId,
  accountId,
  dataModel,
  visibleFooter = true,
  actionsDisabled = false,
  userId,
  deleteActivityCallback,
  readOnly,
}: IEmailThreadDetailProps) => {
  const activityId = activity?.id as unknown as BobjectId;
  const settings = useUserSettings();
  const permissions = usePermissions(settings?.user?.permissions);
  const canHandleEmails = permissions.get(UserPermission.EMAIL_CHANNEL);
  visibleFooter = visibleFooter && canHandleEmails;
  const [selectedActivities, setSelectedActivities] = useState<BobjectId[]>([activityId]);
  const { activities, mutate } = useEmailThreadActivities(emailThreadId, accountId);
  const { t } = useTranslation();
  const { fetchBody, fillEmailActivityWithBody } = useEmailActivities();
  const isSingleEmail = activities?.length === 1;

  const handleClickRow = useCallback((activity: Bobject) => {
    setSelectedActivities(prev => {
      if (prev.find(act => act?.value === activity?.id?.value)) {
        return prev.filter(act => act?.value !== activity?.id?.value);
      }
      return [...prev, activity?.id];
    });
  }, []);

  useEffect(() => {
    setSelectedActivities([activity?.id]);
  }, [activity?.id?.value]);

  const activitiesToRender = useMemo(
    () => (emailThreadId ? activities : [activity]),
    [emailThreadId, activities, activity],
  );

  const { bobjectsSync } = useSyncBobjectStatus(
    activitiesToRender?.[0]?.id?.accountId,
    activitiesToRender,
  );

  const setEmailVariablesValue = useBaseSetEmailVariablesValues();
  const { openMinimizableModal } = useMinimizableModals();

  const emailMetadataString = getValueFromLogicRole(
    activity,
    ACTIVITY_FIELDS_LOGIC_ROLE.EMAIL_METADATA,
  );
  const emailMetadata = emailMetadataString ? JSON.parse(emailMetadataString) : {};
  const emailToArray: string[] =
    !!emailMetadata &&
    [emailMetadata.from?.[0]?.email].concat(emailMetadata.to?.map(e => e.email))?.filter(Boolean);
  const emailCcArray: string[] = !!emailMetadata && emailMetadata.cc?.map(e => e.email);

  const handleOpenEmailModal = useCallback(
    async (event, emailTo, emailCc) => {
      event.preventDefault();
      event.stopPropagation();
      mixpanel.track(MIXPANEL_EVENTS.CLICK_ON_REPLY_FROM_EMAIL_EXTENDED_SCREEN_FOOTER);
      setEmailVariablesValue({
        company: getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.COMPANY)
          ?.referencedBobject,
        lead: getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.LEAD)?.referencedBobject,
        opportunity: getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.OPPORTUNITY)
          ?.referencedBobject,
      });
      let templateSubject = getValueFromLogicRole(
        activity,
        ACTIVITY_FIELDS_LOGIC_ROLE.MESSAGE_SUBJECT,
        true,
      );

      if (
        templateSubject?.includes('"type":"p"') &&
        typeof templateSubject === 'string' &&
        typeof JSON.parse(templateSubject) === 'object'
      ) {
        templateSubject = removeHtmlTags(serialize(templateSubject));
      }
      openMinimizableModal({
        type: 'email',
        title: removeHtmlTags(templateSubject),
        data: {
          template: {
            body: '',
            subject: templateSubject || '',
          },
          mode: EMAIL_MODE.REPLY,
          activity: await fillEmailActivityWithBody(activity),
          company: getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.COMPANY)
            ?.referencedBobject,
          lead: getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.LEAD)?.referencedBobject,
          pageBobjectType: getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.LEAD)
            ? BobjectTypes.Lead
            : BobjectTypes.Company,
          ...(emailTo ? { defaultToEmail: emailTo.map(email => email?.toLowerCase()) } : {}),
          ...(emailCc ? { defaultCcEmail: emailCc.map(email => email?.toLowerCase()) } : {}),
        },
        onSave: () => {
          window.dispatchEvent(
            new CustomEvent(MessagesEvents.ActiveBobjectUpdated, {
              detail: { type: BobjectTypes.Activity },
            }),
          );
        },
      });
    },
    [activity, openMinimizableModal, setEmailVariablesValue],
  );

  const referenceBobject = useMemo(() => getReferencedBobject(activity), [activity]);

  const assignee = useMemo(
    () =>
      getFieldByLogicRole(
        referenceBobject as unknown as Bobject,
        LEAD_FIELDS_LOGIC_ROLE.ASSIGNED_TO,
        // @ts-ignore
      )?.value || referenceBobject?.assignedTo,
    [referenceBobject],
  );

  const assignedToActiveUser = assignee === userId;

  const syncStatus = useMemo(
    () => bobjectsSync?.find(sync => sync?.bobjectId === activity?.id?.objectId)?.syncStatusOk,
    [bobjectsSync, activity?.id?.objectId],
  );

  return (
    <div className={styles.container}>
      <div className={styles.emailDetail_container}>
        {!activitiesToRender ? (
          <div className={styles.loading}>
            <Spinner name="loadingCircle" />
          </div>
        ) : (
          <Timeline size="small">
            {activitiesToRender?.map((activity: Bobject, index: number) => {
              const isSelected = selectedActivities
                ?.map(act => act?.value)
                .includes(activity?.id?.value);
              return (
                <div key={activity?.id?.value + '-' + index}>
                  <ActivityTimelineItem
                    activity={activity}
                    key={activity?.id?.value}
                    onClick={handleClickRow}
                    startDisplayDivider={activities?.length > 1 && index !== 0}
                    endDisplayDivider={activities?.length > 1 && index + 1 !== activities?.length}
                    activeHover={!isSelected}
                    extended
                    dataModel={dataModel}
                    alternativeDescription
                    syncStatus={syncStatus}
                    readOnly={readOnly}
                    {...(deleteActivityCallback && {
                      deleteActivityCallback: () => {
                        deleteActivityCallback(activity?.id?.value, isSingleEmail);
                        if (!isSingleEmail) {
                          mutate();
                        }
                      },
                    })}
                  />
                  {isSelected && (
                    <EmailThreadDetailedActivity activity={activity} fetchBody={fetchBody} />
                  )}
                </div>
              );
            })}
          </Timeline>
        )}
      </div>
      {visibleFooter && (
        <DetailsFooter>
          <>
            {getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.DIRECTION) ===
              ACTIVITY_DIRECTION.INCOMING && (
              <Button
                variant="secondary"
                onClick={e => handleOpenEmailModal(e, emailToArray, emailCcArray)}
                disabled={actionsDisabled && !assignedToActiveUser}
              >
                <Icon name="replyAll" size={14} />
                {t('activityTimelineItem.item.replyAll')}
              </Button>
            )}
            <Button
              variant="secondary"
              onClick={e =>
                handleOpenEmailModal(
                  e,
                  getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.DIRECTION) ===
                    ACTIVITY_DIRECTION.INCOMING ||
                    (emailToArray && emailToArray?.length === 0)
                    ? undefined
                    : emailToArray,
                  getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.DIRECTION) ===
                    ACTIVITY_DIRECTION.INCOMING ||
                    (emailCcArray && emailCcArray?.length === 0)
                    ? undefined
                    : emailCcArray,
                )
              }
              disabled={actionsDisabled && !assignedToActiveUser}
            >
              <Icon
                name={
                  getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.DIRECTION) ===
                  ACTIVITY_DIRECTION.INCOMING
                    ? 'reply'
                    : 'mail'
                }
                size={14}
              />
              {getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.DIRECTION) ===
              ACTIVITY_DIRECTION.INCOMING
                ? t('activityTimelineItem.item.reply')
                : t('activityTimelineItem.item.sendAnother')}
            </Button>
          </>
        </DetailsFooter>
      )}
    </div>
  );
};

interface IEmailThreadDetailWithAIProps extends IEmailThreadDetailProps {
  isBubble?: boolean;
  onOpenEmailModal?: (text: string) => void;
}
export const EmailThreadDetailWithAI = ({
  isBubble,
  onOpenEmailModal,
  activity,
  ...rest
}: IEmailThreadDetailWithAIProps) => {
  return (
    <CopilotActivityContextProvider activity={activity}>
      <ActivityWithIAWrapper
        activity={activity}
        isBubble={isBubble}
        onOpenEmailModal={onOpenEmailModal}
        regenerateAvailable
      >
        <EmailThreadDetail activity={activity} readOnly {...rest} />
      </ActivityWithIAWrapper>
    </CopilotActivityContextProvider>
  );
};
