import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Item, Section, Select, Spinner, Text } from '@bloobirds-it/flamingo-ui';
import { useActiveUserSettings } from '@bloobirds-it/hooks';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  BobjectId,
  BobjectTypes,
  LogicRoleType,
  MessagesEvents,
  OPPORTUNITY_FIELDS_LOGIC_ROLE,
  OPPORTUNITY_STATUS_LOGIC_ROLE,
  RelatedObjectClass,
  SALESFORCE_LOGIC_ROLES,
} from '@bloobirds-it/types';
import {
  api,
  getFieldByLogicRole,
  getTextFromLogicRole,
  searchOppByLeadOrCompany,
} from '@bloobirds-it/utils';
import { useWizardContext } from '@bloobirds-it/wizard-modal-context';
import { AnimatePresence } from 'framer-motion';

import { useGetMainObjects } from '../../hooks/useGetMainObjects';
import { useRelatedAssignment } from '../../hooks/useRelatedAssignment';
import styles from './assingRelatedSelect.module.css';

const invalidOppStatuses = {
  [OPPORTUNITY_FIELDS_LOGIC_ROLE.STATUS]: [
    OPPORTUNITY_STATUS_LOGIC_ROLE.CLOSED_LOST,
    OPPORTUNITY_STATUS_LOGIC_ROLE.CLOSED_WON,
  ],
  SALESFORCE_OPPORTUNITY__STAGE: ['Closed Lost', 'Closed Won'],
} as const;

export const AssignRelatedSelect = ({
  activityId,
  activityRelated,
  referenceBobjectType,
  send,
  wizardKey,
}: {
  activityId: BobjectId<BobjectTypes.Activity>['value'];
  activityRelated?: string | string[];
  referenceBobjectType: BobjectTypes;
  send?: (key: string, value: any) => void;
  wizardKey: string;
}) => {
  const { data: mainObjects, isLoading } = useGetMainObjects();
  const { t } = useTranslation('translation', { keyPrefix: 'relatedObjects.assignRelatedSelect' });
  const { t: OppT } = useTranslation();
  const { getWizardProperties } = useWizardContext();
  const {
    bobject: activity,
    activityLead,
    activityCompany,
    activityOpportunity,
  } = getWizardProperties(wizardKey);
  const [availableOpps, setAvailableOpps] = useState({
    selectedOpportunityObject: activityOpportunity,
    selectedOpportunityArray: activityOpportunity ? [activityOpportunity] : [],
  });
  const [loadingOpps, setLoadingOpps] = useState(true);
  const { settings } = useActiveUserSettings();
  const accountId = settings?.account?.id;
  const userId = settings?.user?.id;

  const { addRelatedToBobject, activityAllRelated } = useRelatedAssignment({
    bobjectId: activityId,
    referenceBobjectType,
  });
  const defaultValue =
    (typeof activityRelated === 'string' ? activityRelated : activityRelated?.[0]) ||
    activityOpportunity ||
    availableOpps.selectedOpportunityObject;

  const [selectValue, setSelectValue] = useState(defaultValue);
  const hasOptions = !!(
    availableOpps?.selectedOpportunityArray?.length + activityAllRelated?.length
  );
  const shouldDisplaySelect =
    (!isLoading &&
      mainObjects?.find(object => object.objectType === BobjectTypes.Opportunity).display) ||
    hasOptions;

  const handleChange = value => {
    //related ids are strings opps are objects
    if (!value || value.id?.value) {
      const newValue = {
        selectedOpportunityObject: value,
        selectedOpportunityArray: availableOpps.selectedOpportunityArray,
      };
      setAvailableOpps(newValue);
      setSelectValue(value);
      send?.('UPDATE_CONTEXT_OPP', newValue);
      api
        .patch(`/bobjects/${activity?.id.value}/raw`, {
          [ACTIVITY_FIELDS_LOGIC_ROLE.OPPORTUNITY]: value ? value?.id.value : null,
        })
        .then(() =>
          window.dispatchEvent(
            new CustomEvent(MessagesEvents.ActiveBobjectUpdated, {
              detail: { type: BobjectTypes.Activity },
            }),
          ),
        );
    } else {
      addRelatedToBobject(availableOpps.selectedOpportunityObject);
    }
  };

  useEffect(() => {
    if (!activityOpportunity)
      searchOppByLeadOrCompany(
        accountId,
        activityLead?.id?.value,
        activityCompany?.id?.value,
        userId,
      )
        .then(response => {
          if (response?.data?.totalMatching > 0) {
            const idealOpps = response?.data?.contents?.filter(opp => {
              return !Object.entries(invalidOppStatuses).some(
                ([fieldLogicRole, invalidStatuses]: [
                  LogicRoleType<BobjectTypes.Opportunity> | SALESFORCE_LOGIC_ROLES,
                  any,
                ]) => {
                  return invalidStatuses.includes(
                    getFieldByLogicRole<BobjectTypes.Opportunity>(opp, fieldLogicRole)
                      ?.valueLogicRole,
                  );
                },
              );
            });
            //Needed to auto assign default selected opp, the management should be more direct but it would require refactor
            if (idealOpps?.[0]) {
              api.patch(`/bobjects/${activity?.id.value}/raw`, {
                [ACTIVITY_FIELDS_LOGIC_ROLE.OPPORTUNITY]: idealOpps[0]
                  ? idealOpps[0].id.value
                  : null,
              });
            }
            setAvailableOpps({
              selectedOpportunityObject: activityOpportunity || idealOpps[0],
              selectedOpportunityArray: idealOpps,
            });
          }
        })
        .finally(() => setLoadingOpps(false));
  }, []);

  if (activityOpportunity) return null;

  if (loadingOpps || isLoading)
    return (
      <div className={styles.wrapper}>
        <Spinner name="dots" size={18} />
      </div>
    );

  return (
    <AnimatePresence>
      {shouldDisplaySelect && hasOptions ? (
        <div className={styles.wrapper}>
          <Text size="m" color="peanut" weight="medium">
            {t('title')}
          </Text>
          <Select
            size="small"
            width="100%"
            value={selectValue}
            defaultValue={defaultValue}
            onChange={handleChange}
            autocomplete
            placeholder={t(hasOptions ? 'placeholder' : 'disabledPlaceholder')}
            renderDisplayValue={value => {
              if (!value) return t(hasOptions ? 'placeholder' : 'disabledPlaceholder');
              return value?.id?.value
                ? getTextFromLogicRole(value, OPPORTUNITY_FIELDS_LOGIC_ROLE.NAME)
                : value;
            }}
          >
            <Item key="none" value={null} label={t('edition.none')}>
              {t('none')}
            </Item>
            {loadingOpps ? (
              <div
                style={{ width: '100%', justifyContent: 'center', display: 'flex', padding: '8px' }}
              >
                <Spinner name="dots" size={18} />
              </div>
            ) : availableOpps.selectedOpportunityArray.length === 0 ? null : (
              [
                <Section key={availableOpps.selectedOpportunityArray[0]?.id.value}>
                  {OppT('common.opportunity')}
                </Section>,
                ...availableOpps.selectedOpportunityArray.map(opp => {
                  return (
                    <Item key={opp.id.value} value={opp}>
                      {getTextFromLogicRole(opp, OPPORTUNITY_FIELDS_LOGIC_ROLE.NAME)}
                    </Item>
                  );
                }),
              ]
            )}
            {activityAllRelated?.map((rel, index) => {
              return [
                <Section key={rel?.relatedObjectType + '_' + index}>
                  {rel?.relatedObjectType}
                </Section>,
                ...rel?.relatedSobjects?.map(rawR => {
                  const r = new RelatedObjectClass(rawR);
                  return (
                    <Item
                      key={r?.compositeRelatedId}
                      value={r?.compositeRelatedId}
                      label={r?.title}
                    >
                      {r.title}
                    </Item>
                  );
                }),
              ];
            })}
          </Select>
        </div>
      ) : null}
    </AnimatePresence>
  );
};
