import React from 'react';

import { Text } from '@bloobirds-it/flamingo-ui';
import Markdown, { MarkdownToJSX } from 'markdown-to-jsx';

import styles from './markdownReader.module.css';

interface MarkdownReaderProps {
  text: string;
}
export const MarkdownReader: React.FC<MarkdownReaderProps> = ({ text = '' }) => {
  const createOverride = (size: 'l' | 'm' | 'xs', htmlTag: string) => ({
    component: Text,
    props: {
      size,
      weight: size === 'xs' ? 'regular' : 'bold',
      color: size === 'xs' ? 'peanut' : 'black',
      htmlTag,
    },
  });

  const options: MarkdownToJSX.Options = {
    wrapper: 'article',
    forceBlock: true,
    overrides: {
      h1: createOverride('l', 'h1'),
      h2: createOverride('m', 'h2'),
      p: createOverride('xs', 'p'),
      span: createOverride('xs', 'p'),
      a: createOverride('xs', 'a'),
      article: createOverride('xs', 'article'),
    },
  };

  return (
    <div className={styles.markdownWrapper}>
      <Markdown options={options}>{text}</Markdown>
    </div>
  );
};
