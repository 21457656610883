import { useTranslation } from 'react-i18next';

import { Button, Icon, IconType, Spinner, Text } from '@bloobirds-it/flamingo-ui';
import { HxTexturedBackground } from '@bloobirds-it/misc';
import { ExtensionBobject } from '@bloobirds-it/types';
import clsx from 'clsx';

import { useCopilotObjects } from '../hooks/useCopilotObjects';
import styles from './accountSummary.module.css';

interface IAccountSummaryProps {
  bobject: ExtensionBobject;
  copilotFieldId: string;
  openAnalysisScreen: () => void;
}
const OBJECT_ICON: Record<string, IconType> = {
  Opportunity: 'opportunityStars',
  Lead: 'leadStars',
  Company: 'companyStars',
};

export const AccountSummary = ({
  bobject,
  copilotFieldId,
  openAnalysisScreen,
}: IAccountSummaryProps) => {
  const { t } = useTranslation();
  const { generate, analysisHistory, loadingHistory } = useCopilotObjects(bobject?.id);
  if (!bobject || !copilotFieldId) return null;
  const latestCopilotAnalysisPk = bobject.rawBobject[copilotFieldId];
  const openAnalysisExtendedView = () => {
    if (loadingHistory) return;
    if (!latestCopilotAnalysisPk) {
      generate();
    }
    openAnalysisScreen();
  };

  let latestAnalysis = (analysisHistory || []).find(
    analysis => analysis.pk === latestCopilotAnalysisPk,
  );
  if (!latestAnalysis && analysisHistory?.length) {
    latestAnalysis = analysisHistory[0];
  }
  let buttonLiteral =
    latestAnalysis?.status === 'GENERATING' ? 'ai.aiInsights.generating' : 'ai.accountSummary.from';
  if (loadingHistory && !latestAnalysis) {
    buttonLiteral = '';
  }
  return (
    <div className={styles.textureWrapper}>
      <HxTexturedBackground
        className={clsx(styles.container, { [styles.pointer]: !loadingHistory })}
        onClick={openAnalysisExtendedView}
      >
        <Icon name={OBJECT_ICON[bobject?.id?.typeName]} size={16} color="white" />
        {loadingHistory ? (
          <div className={styles.loadingDots}>
            <Spinner name="dots" color="white" />
          </div>
        ) : (
          <div
            className={styles.literalWrapper}
            style={{ justifyContent: latestAnalysis ? 'space-between' : 'space-around' }}
          >
            <Text size="s" weight="heavy" color="white" className={styles.paragraph}>
              {t(
                !latestAnalysis
                  ? 'ai.accountSummary.launch'
                  : `ai.accountSummary.title.${bobject?.id?.typeName}`,
              )}
            </Text>
            {latestAnalysis && (
              <Text size="xs" weight="regular" color="white" className={styles.paragraph}>
                {t(buttonLiteral, { generationDate: latestAnalysis.requested_at })}
              </Text>
            )}
          </div>
        )}
      </HxTexturedBackground>
    </div>
  );
};
