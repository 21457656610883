import React, { createContext, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  ColorType,
  ExpandableBox,
  Icon,
  IconType,
  Text,
  Tooltip,
} from '@bloobirds-it/flamingo-ui';
import { useActiveUserSettings, useUserHelpers } from '@bloobirds-it/hooks';
import { CRM, SignaturesKeys, StepState, UserHelperKeys } from '@bloobirds-it/types';
import clsx from 'clsx';
import { TFunction } from 'i18next';

import { useQuickStartGuideCompleted } from '../../../../hooks/useQuickStartGuide';
import { getIsCompleted } from '../../../../pages/homePage/components/quickStartGuide/quickStartGuide.utils';
import { useUserSettings } from '../../../userPermissions/hooks';
import { BringSignatures } from './components/bringSignatures';
import { ConnectToSFDC } from './components/connectSalesforce';
import { DialerSetter } from './components/dialerSetter';
import { EmailSetter } from './components/emailSetter';
import ExpandableSubsection from './components/expandableSubsection';
import OpenExtension from './components/openExtension';
import { RemindersSetter } from './components/reminderSetter';
import { StepWrapper } from './components/stepWrapper';
import { TimezoneSetter } from './components/timezoneSetter';
import styles from './otoConnectionsGuide.module.css';

export type QuickStartGuideBlock = {
  title: string;
  key: UserHelperKeys;
  icon: IconType;
  iconColor: ColorType;
  action?: { label: string; key: UserHelperKeys };
  skipButtonText?: string;
};

export const qsgTourKeys = ({
  t,
  hasEmail = false,
  isRevolut = false,
}: {
  t: TFunction;
  hasEmail?: boolean;
  isRevolut?: boolean;
}): QuickStartGuideBlock[] => {
  return [
    ...[
      !isRevolut && {
        title: t('quickStartGuide.oto.blocks.dialer.title'),
        key: UserHelperKeys.CHOOSE_DIALER,
        icon: 'phone',
        iconColor: 'bloobirds',
        skipButtonText: t('quickStartGuide.oto.blocks.dialer.skipButtonText'),
      },
    ],
    {
      title: hasEmail
        ? t('quickStartGuide.oto.blocks.email.titleSignature')
        : t('quickStartGuide.oto.blocks.email.title'),
      key: UserHelperKeys.SET_YOUR_EMAIL_SIGNATURE,
      icon: 'mail',
      iconColor: 'bloobirds',
      skipButtonText: hasEmail
        ? t('quickStartGuide.oto.blocks.email.skipButtonTextSignature')
        : t('quickStartGuide.oto.blocks.email.skipButtonText'),
    },
    {
      title: t('quickStartGuide.oto.blocks.signatures.title'),
      key: UserHelperKeys.BRING_SIGNATURES,
      icon: 'signature',
      iconColor: 'bloobirds',
      skipButtonText: t('quickStartGuide.oto.blocks.taskAndReminders.skipButtonText'),
    },
    ...[
      !isRevolut && {
        title: t('quickStartGuide.oto.blocks.taskAndReminders.title'),
        key: UserHelperKeys.SET_UP_REMINDERS,
        icon: 'taskAction',
        iconColor: 'bloobirds',
        skipButtonText: t('quickStartGuide.oto.blocks.taskAndReminders.skipButtonText'),
      },
    ],
    {
      title: t('quickStartGuide.oto.blocks.timezone.title'),
      key: UserHelperKeys.SET_TIMEZONE,
      icon: 'timezones',
      iconColor: 'bloobirds',
      skipButtonText: t('quickStartGuide.oto.blocks.timezone.skipButtonText'),
    },
    {
      title: t('quickStartGuide.oto.blocks.sfdc.title'),
      key: UserHelperKeys.CONNECT_SALESFORCE,
      icon: 'salesforceOutlined',
      iconColor: 'bloobirds',
      skipButtonText: t('quickStartGuide.oto.blocks.sfdc.skipButtonText'),
    },
    !isRevolut && {
      title: t('quickStartGuide.oto.blocks.start.title'),
      key: UserHelperKeys.DOWNLOAD_CHROME_EXTENSION,
      icon: 'chrome',
      iconColor: 'bloobirds',
    },
  ].filter(Boolean) as QuickStartGuideBlock[];
};

const componentsWithWrapper = [
  UserHelperKeys.SET_YOUR_EMAIL_SIGNATURE,
  UserHelperKeys.SET_TIMEZONE,
  UserHelperKeys.CHOOSE_DIALER,
  UserHelperKeys.SET_UP_REMINDERS,
  UserHelperKeys.CONNECT_SALESFORCE,
];

type StepContextType = {
  openSteps: { [key: number]: boolean };
  toggleStep: (stepIndex: number) => void;
  setStateSignatures: (value: string) => void;
  stateSignatures: string;
};

const StepContext = createContext<StepContextType | null>(null);

export const useStepContext = () => {
  const context = useContext(StepContext);
  if (!context) {
    throw new Error('useStepContext debe usarse dentro de StepContext.Provider');
  }
  return context;
};

const StepContent = ({
  type,
  handleFinish,
  allTasksCompleted,
  isCompleted = false,
  step,
  setIsOpen,
}: {
  type: UserHelperKeys;
  handleFinish: () => void;
  allTasksCompleted: boolean;
  isCompleted?: boolean;
  step: QuickStartGuideBlock;
  setIsOpen: (value: boolean) => void;
}) => {
  const renderComponent = () => {
    switch (type) {
      case UserHelperKeys.DOWNLOAD_CHROME_EXTENSION:
        return <OpenExtension handleFinish={handleFinish} allTasksCompleted={allTasksCompleted} />;
      case UserHelperKeys.SET_YOUR_EMAIL_SIGNATURE:
        return <EmailSetter />;
      case UserHelperKeys.CONNECT_SALESFORCE:
        return <ConnectToSFDC />;
      case UserHelperKeys.SET_TIMEZONE:
        return <TimezoneSetter />;
      case UserHelperKeys.BRING_SIGNATURES:
        return <BringSignatures setIsOpen={setIsOpen} step={step} />;
      case UserHelperKeys.CHOOSE_DIALER:
        return <DialerSetter />;
      case UserHelperKeys.SET_UP_REMINDERS:
        return <RemindersSetter />;
      default:
        return null;
    }
  };

  const component = renderComponent();

  return componentsWithWrapper.includes(type) ? (
    <StepWrapper isCompleted={isCompleted} step={step} setIsOpen={setIsOpen}>
      {component}
    </StepWrapper>
  ) : (
    component
  );
};

const Steps = ({ handleFinish }: { handleFinish: () => void }) => {
  const { userHelpers }: { userHelpers: Record<UserHelperKeys, Date> } = useUserSettings();
  const { settings } = useActiveUserSettings();
  const { has } = useUserHelpers();
  const { t } = useTranslation();
  const [openSteps, setOpenSteps] = useState<{ [key: number]: boolean }>({});
  const [stateSignatures, setStateSignatures] = useState(
    getIsCompleted(userHelpers, UserHelperKeys.BRING_SIGNATURES, {}) ||
      has(UserHelperKeys.BRING_SIGNATURES)
      ? 'YES'
      : null,
  );

  // TODO Remove this after Revolut onboarding, checking if account is Revolut or Tofu
  const isRevolut =
    settings?.account?.id === '1ukQPEbwsnOxNfVs' || settings?.account?.id === '7VA3TbSzLkrOE3Ud';
  const stepsKeys = qsgTourKeys({ t }).map(step => step.key);
  const shouldShowSFDCStep =
    settings?.account?.salesforceInstance && settings?.account?.mainCrm === CRM.SALESFORCE;

  const [steps, setSteps] = useState({
    steps: qsgTourKeys,
    opened: UserHelperKeys.CHOOSE_DIALER,
  });

  const allTasksCompleted = [
    ...(shouldShowSFDCStep ? [UserHelperKeys.CONNECT_SALESFORCE] : []),
    UserHelperKeys.SET_TIMEZONE,
    ...(isRevolut ? [] : [UserHelperKeys.CHOOSE_DIALER]),
    UserHelperKeys.SET_YOUR_EMAIL_SIGNATURE,
    ...(isRevolut ? [] : [UserHelperKeys.SET_UP_REMINDERS]),
  ].every(key => has(key));

  const toggleStep = (stepIndex: number) => {
    setOpenSteps(prev => ({
      ...prev,
      [stepIndex]: !prev[stepIndex],
    }));
  };

  const autoOpenStepsKeys = [
    UserHelperKeys.CONNECT_SALESFORCE,
    ...(isRevolut ? [] : [UserHelperKeys.DOWNLOAD_CHROME_EXTENSION]),
  ];

  useEffect(() => {
    const openedStep = autoOpenStepsKeys.find(s => {
      const previousStep = stepsKeys[stepsKeys.indexOf(s) - 1];
      return Object.keys(userHelpers).includes(previousStep);
    });
    setSteps({ ...steps, ...(openedStep ? { opened: openedStep } : {}) });
  }, [userHelpers.CONNECT_SALESFORCE, userHelpers.SET_UP_REMINDERS]);

  return (
    <StepContext.Provider
      value={{
        openSteps,
        toggleStep,
        stateSignatures,
        setStateSignatures,
      }}
    >
      <div key={steps.opened}>
        {steps
          .steps({ t, hasEmail: !!userHelpers?.CONNECT_EMAIL, isRevolut })
          .map((step, index) => {
            if (step.key === UserHelperKeys.CONNECT_SALESFORCE && !shouldShowSFDCStep) return null;
            const isCompleted = getIsCompleted(userHelpers, step.key, {}) || has(step.key);
            const isBlocked =
              (step.key === UserHelperKeys.DOWNLOAD_CHROME_EXTENSION && !allTasksCompleted) ||
              (step.key === UserHelperKeys.BRING_SIGNATURES &&
                !getIsCompleted(userHelpers, UserHelperKeys.SET_YOUR_EMAIL_SIGNATURE, {}));
            const isSkipped =
              stateSignatures === SignaturesKeys.SKIPPED &&
              step.key === UserHelperKeys.BRING_SIGNATURES;

            const getStepState = () => {
              if (isCompleted) return StepState.COMPLETED;
              if (isBlocked) return StepState.BLOCKED;
              if (isSkipped) return StepState.SKIPPED;
              return StepState.PENDING;
            };
            const currentState = getStepState();

            const tooltipTitle = (() => {
              if (step.key === UserHelperKeys.BRING_SIGNATURES) {
                return t('quickStartGuide.oto.blocks.signatures.tooltip');
              }
              if (step.key === UserHelperKeys.DOWNLOAD_CHROME_EXTENSION && !allTasksCompleted) {
                return t('quickStartGuide.oto.blocks.start.completeSteps');
              }
              return '';
            })();

            return !shouldShowSFDCStep && step.key === UserHelperKeys.CONNECT_SALESFORCE ? null : (
              <Tooltip
                className={styles.expandWidth}
                title={isBlocked ? tooltipTitle : ''}
                position="top"
              >
                <ExpandableSubsection
                  title={step.title}
                  content={
                    <StepContent
                      type={step.key}
                      handleFinish={handleFinish}
                      allTasksCompleted={allTasksCompleted}
                      isCompleted={isCompleted}
                      step={step}
                      setIsOpen={() => toggleStep(index)}
                    />
                  }
                  leftIcon={step.icon}
                  state={currentState}
                  isOpen={!!openSteps[index]}
                  setIsOpen={() => toggleStep(index)}
                />
              </Tooltip>
            );
          })}
        {!isRevolut && allTasksCompleted && has(UserHelperKeys.DOWNLOAD_CHROME_EXTENSION) && (
          <Button
            className={styles.startButton}
            onClick={handleFinish}
            iconLeft="bloobirds"
            variant="tertiary"
          >
            {t('quickStartGuide.oto.blocks.start.title')} !
          </Button>
        )}
      </div>
    </StepContext.Provider>
  );
};

export const OTOConnectionsGuide = React.forwardRef(
  (
    {
      handleFinish,
      isHomePage = false,
      ...props
    }: { handleFinish: () => void; isHomePage?: boolean },
    ref: React.LegacyRef<HTMLDivElement>,
  ) => {
    const isQSGCompleted = useQuickStartGuideCompleted();
    const { t } = useTranslation('translation', { keyPrefix: 'quickStartGuide.oto' });

    return isHomePage ? (
      <div ref={ref} {...props} style={{ margin: 8 }}>
        <ExpandableBox
          defaultOpened={isHomePage ? !isQSGCompleted : true}
          nonCollapsable={!isHomePage}
          title={t('title')}
          subtitle={!isQSGCompleted && <Trans i18nKey="quickStartGuide.oto.subtitle" />}
          progressPercentage={isQSGCompleted ? 100 : 0}
          progressText={isQSGCompleted && t('progressText')}
        >
          <OTOConnectionsGuideContent handleFinish={handleFinish} isHomePage={isHomePage} />
        </ExpandableBox>
      </div>
    ) : (
      <div className={styles.WSContainer}>
        <div className={styles.textIconContainer}>
          <div className={styles.checkIcon}>
            <Icon name="check" color="melon" size={20} />
          </div>
          <Text size="s" align="center" color="softPeanut">
            <Trans
              i18nKey="quickStartGuide.oto.subtitleStyles"
              components={{
                highlight: <span style={{ color: 'var(--melon)', fontWeight: 'bold' }} />,
              }}
            />
          </Text>
        </div>
        <OTOConnectionsGuideContent handleFinish={handleFinish} isHomePage={isHomePage} />
      </div>
    );
  },
);

export const OTOConnectionsGuideContent = ({
  handleFinish,
  isHomePage = false,
}: {
  handleFinish: () => void;
  isHomePage?: boolean;
}) => (
  <div className={clsx(styles.qsgContainer, { [styles.qsgContainerWS]: !isHomePage })}>
    <Steps handleFinish={handleFinish} />
  </div>
);
