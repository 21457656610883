import React from 'react';
import { FormProvider } from 'react-hook-form';

import { Button, createToast, ModalContent, ModalFooter, Spinner } from '@bloobirds-it/flamingo-ui';
import { useObjectCreationSettings } from '@bloobirds-it/hooks';
import { BobjectTypes, MessagesEvents, MIXPANEL_EVENTS } from '@bloobirds-it/types';
import { EVENTS } from '@bloobirds-it/wizard-modal-context';
import mixpanel from 'mixpanel-browser';

import { NoteColumn } from './components/noteColumn/noteColumn';
import { QuickActionColumn } from './components/quickActionColumn/quickActionColumn';
import { StatusColumn } from './components/statusColumn/statusColumn';
import { StatusNoteActionProvider, useStatusNoteActionContext } from './hooks/useStatusNoteActions';
import styles from './statusNoteActions.module.css';
import { NoteMode } from './types/statusNoteActions.types';

const withProvider = Component => props => {
  return (
    <StatusNoteActionProvider {...props}>
      <Component />
    </StatusNoteActionProvider>
  );
};

const StatusNoteActionsComponent = () => {
  const {
    handleUpdateStatus,
    loading,
    send,
    t,
    formMethods,
    bobject,
    buttonsConfig,
    setLoading,
    hasSaved,
    hasSavedMainNote,
    statusHasChangedState,
    hasAnyStatus,
    instantSave,
    instantMainNoteSave,
    noteMode,
  } = useStatusNoteActionContext();
  const [statusHasChanged] = statusHasChangedState || [];
  const { enabledChangeStatus } = useObjectCreationSettings();

  function handleSubmit(manageTasks) {
    setLoading(true);
    if (hasSaved?.current || hasSavedMainNote?.current) {
      mixpanel.track(MIXPANEL_EVENTS.STATUS_NOTE_ACTIONS_AUTO_SAVED_NOTE);
    }

    if (noteMode === NoteMode.CallMode) {
      instantSave();
    } else {
      instantMainNoteSave();
    }

    //TODO if more fields are gonna be updated maybe we can move this callback to the context
    handleUpdateStatus(formMethods.getValues())
      .then(response => {
        setLoading(false);
        createToast({
          message: t('toasts.updateSalesforceSuccess'),
          type: 'success',
        });

        if (response === 'noUpdates' && !manageTasks) {
          mixpanel.track(MIXPANEL_EVENTS.STATUS_NOTE_ACTIONS_FINISH_REPORTING);
          return send(EVENTS.FINISH);
        }
        if (manageTasks) {
          mixpanel.track(MIXPANEL_EVENTS.STATUS_NOTE_ACTIONS_GO_TO_MANAGE_TASKS);
          send(EVENTS.NEXT, { selectedOpportunityObject: bobject, manageTasks: true });
        } else {
          mixpanel.track(MIXPANEL_EVENTS.STATUS_NOTE_ACTIONS_FINISH_REPORTING);
          send(EVENTS.FINISH);
        }

        if (statusHasChanged) {
          mixpanel.track(MIXPANEL_EVENTS.STATUS_NOTE_ACTIONS_CHANGE_STATUS);
        }
        //TODO this should be improved
        window.dispatchEvent(
          new CustomEvent(MessagesEvents.ActiveBobjectUpdated, {
            detail: { type: bobject?.id.typeName },
          }),
        );
        window.dispatchEvent(new CustomEvent(MessagesEvents.UpdateContext));
      })
      .catch(e => {
        setLoading(false);
        createToast({
          message: t('toasts.updateSalesforceError', {
            error: e?.response?.data?.message ? `: ${e.response.data.message}` : '.',
          }),
          type: 'error',
        });
      });
  }

  return (
    <FormProvider {...formMethods}>
      <ModalContent className={styles.modalContent}>
        {hasAnyStatus && enabledChangeStatus && (
          <div className={styles.sectionContainer}>
            <StatusColumn />
          </div>
        )}
        <div className={styles.sectionContainer}>
          <NoteColumn />
        </div>
        <div className={styles.sectionContainer}>
          <QuickActionColumn />
        </div>
      </ModalContent>
      <ModalFooter className={styles._modal_footer}>
        <div>
          <Button variant="clear" onClick={() => send(EVENTS.PREVIOUS)}>
            {t('buttons.back')}
          </Button>
        </div>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button
            onClick={() => handleSubmit(true)}
            className={styles.stepActions_button}
            disabled={loading}
            variant="secondary"
          >
            {loading ? (
              <Spinner name="loadingCircle" size={12} />
            ) : (
              buttonsConfig?.nextButtonTitle || t('buttons.manageTasks')
            )}
          </Button>
          <Button
            onClick={() => handleSubmit(false)}
            className={styles.stepActions_button}
            disabled={loading}
          >
            {loading ? <Spinner name="loadingCircle" size={12} /> : t('buttons.finishReporting')}
          </Button>
        </div>
      </ModalFooter>
    </FormProvider>
  );
};

export const StatusNoteActions = withProvider(StatusNoteActionsComponent);
