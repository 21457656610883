import React from 'react';

import { BobjectField, BobjectTypes, CopilotInsight, CopilotObjectType } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import useSWR from 'swr';

const containsAnyWholeWord = (str, words) => {
  return words.some(word => {
    const regex = new RegExp(`(^|[^a-zA-Z0-9])${word}($|[^a-zA-Z0-9])`);
    return regex.test(str);
  });
};

interface IUseGetCoreInsightsParams {
  type?: BobjectField<BobjectTypes, BobjectTypes>;
  mainTypeField?: BobjectField<BobjectTypes, BobjectTypes>;
  bobjectType?: CopilotObjectType;
}
export const useGetCoreInsights = ({
  type,
  mainTypeField,
  bobjectType = 'Activity',
}: IUseGetCoreInsightsParams): Map<string, CopilotInsight> => {
  const { data: coreInsights } = useSWR<CopilotInsight[]>('/utils/service/copilot-insights', key =>
    api.get<CopilotInsight[]>(key).then(res => res.data),
  );

  const insightsMap = new Map<string, CopilotInsight>();

  coreInsights?.forEach(insight => {
    if (!type && bobjectType === 'Activity') {
      insightsMap.set(insight.id, insight);
      return;
    }

    if (
      bobjectType !== 'Activity' &&
      ['all', bobjectType.toLocaleLowerCase()].includes(insight.objectType?.toLocaleLowerCase())
    ) {
      insightsMap.set(insight.id, insight);
      return;
    }

    // ----------- ACTIVITY related filtering ----------
    const types = ['all', type?.text?.toLocaleLowerCase()];
    const activityTypeMatch = containsAnyWholeWord(
      insight.activityType?.toLocaleLowerCase(),
      types,
    );

    if (!activityTypeMatch) return;

    if (!insight.activitySubtype || insight.activitySubtype.toLocaleLowerCase() === 'all') {
      insightsMap.set(insight.id, insight);
      return;
    }

    if (!mainTypeField?.value) {
      insightsMap.set(insight.id, insight);
      return;
    }

    if (mainTypeField.value === insight.activitySubtype) {
      insightsMap.set(insight.id, insight);
    }
  });

  return insightsMap;
};
