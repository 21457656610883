import { Bobject, BobjectTypes, MainBobjectTypes, Sobject } from '@bloobirds-it/types';
import { api, getSalesforceIdField, getSobjectTypeFromBobject } from '@bloobirds-it/utils';
import useSWR from 'swr';

import { useIsNoStatusPlanAccount } from './useActiveAccount';
import { useDataModel } from './useDataModel';

type RecordTypeResponse = {
  RecordTypeId: string;
};
//TODO extract this
type BobjectRecordTypeInfo = Record<
  Sobject,
  RecordTypeResponse & { bobjectType: MainBobjectTypes }
> & { isLoading: boolean };
export const useBobjectRecordType = (
  bobject: Bobject<MainBobjectTypes>,
  companyRelated?: Bobject<BobjectTypes.Company>,
  opportunityRelated?: Bobject<BobjectTypes.Opportunity>,
): BobjectRecordTypeInfo => {
  const dataModel = useDataModel();
  const hasNoStatusPlanEnabled = useIsNoStatusPlanAccount();
  const sobjectType = getSobjectTypeFromBobject(bobject);

  const { data, isLoading } = useSWR<RecordTypeResponse>(
    hasNoStatusPlanEnabled && 'recordType/' + bobject?.id?.value,
    () => {
      const sobjectId = getSalesforceIdField(bobject, dataModel);
      return api
        .get(`/utils/service/salesforce/getRecordTypeId/${sobjectType}/${sobjectId}`)
        .then(data => data.data);
    },
  );
  const { data: accountRecordType, isLoading: accountLoading } = useSWR<RecordTypeResponse>(
    hasNoStatusPlanEnabled && companyRelated && 'recordType/' + companyRelated?.id?.value,
    () => {
      const sobjectId = getSalesforceIdField(companyRelated, dataModel);
      return api
        .get(`/utils/service/salesforce/getRecordTypeId/Account/${sobjectId}`)
        .then(data => data.data);
    },
  );
  const { data: oppRecordType, isLoading: oppLoading } = useSWR<RecordTypeResponse>(
    hasNoStatusPlanEnabled && opportunityRelated && 'recordType/' + opportunityRelated?.id?.value,
    () => {
      const sobjectId = getSalesforceIdField(opportunityRelated, dataModel);
      return api
        .get(`/utils/service/salesforce/getRecordTypeId/Opportunity/${sobjectId}`)
        .then(data => data.data);
    },
  );

  return {
    [sobjectType]: {
      ...(data || { RecordTypeId: undefined }),
      bobjectType: BobjectTypes.Lead,
    },
    ...(sobjectType !== 'Account' &&
      accountRecordType && {
        Account: {
          ...(accountRecordType || { RecordTypeId: undefined }),
          bobjectType: BobjectTypes.Company,
        },
      }),
    ...(sobjectType !== 'Opportunity' &&
      oppRecordType && {
        Opportunity: {
          ...(oppRecordType || { RecordTypeId: undefined }),
          bobjectType: BobjectTypes.Opportunity,
        },
      }),
    isLoading: isLoading || accountLoading || oppLoading,
  } as BobjectRecordTypeInfo;
};
