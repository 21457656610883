import React from 'react';

import { Button, Icon, Label, RadioGroup, Spinner, Text } from '@bloobirds-it/flamingo-ui';
import { useActiveUserSettings } from '@bloobirds-it/hooks';
import { CRM, OPPORTUNITY_STATUS_LOGIC_ROLE } from '@bloobirds-it/types';
import { getMainBobjectIcon } from '@bloobirds-it/utils';
import { EVENTS } from '@bloobirds-it/wizard-modal-context';

import { useStatusNoteActionContext } from '../../hooks/useStatusNoteActions';
import { ColumnHeader } from '../common/columnHeader';
import { AdditionalInfoSelect } from './components/aditionalInfoSelect';
import { StatusColumnBobjectSelector } from './components/statusColumnBobjectSelector';
import styles from './statusColumn.module.css';
import { getBobjectName } from './statusColumnUtils';

export const StatusColumn = () => {
  const { t, machineContext, bobject } = useStatusNoteActionContext();

  const nameToDisplay = getBobjectName(
    machineContext.selectedOpportunityObject || (bobject ?? machineContext?.referenceBobject),
  );

  return (
    <>
      <ColumnHeader
        icon="activity"
        text={t(nameToDisplay ? 'statusColumn.header' : 'statusColumn.headerWithoutInfo')}
      />
      {machineContext?.selectedOpportunityArray && machineContext.selectedOpportunityObject ? (
        <StatusColumnBobjectSelector
          bobjects={machineContext?.selectedOpportunityArray}
          text={nameToDisplay}
        />
      ) : (
        <div className={styles.status_sub_header}>
          <Icon
            name={getMainBobjectIcon((bobject || machineContext?.referenceBobject)?.id?.typeName)}
            className={styles.headerBobjectIcon}
            color="softPeanut"
            size={18}
          />
          <Text size="s" color="softPeanut">
            {nameToDisplay}
          </Text>
        </div>
      )}
      <ReducedStatusManager />
    </>
  );
};

function StatusSelector() {
  const {
    availableStatuses,
    handleSelectedStatus: [selectedStatus, setSelectedStatus],
    handleSelectedReason: [, setSelectedReason],
    statusHasChangedState,
    statusLoading,
  } = useStatusNoteActionContext();
  const [, setStatusHasChanged] = statusHasChangedState || [];
  const { settings } = useActiveUserSettings();
  if (statusLoading) {
    return <Spinner name="loadingCircle" size={18} />;
  }
  return (
    <div className={styles._radios_list_status}>
      {statusLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', width: '280px' }}>
          <Spinner name="loadingCircle" size={18} />
        </div>
      ) : (
        <RadioGroup
          value={selectedStatus}
          onChange={(value: string) => {
            setSelectedReason(null);
            setSelectedStatus(availableStatuses.find(({ id }) => id === value));
            setStatusHasChanged?.(true);
          }}
        >
          {availableStatuses?.map(status => {
            const isSelected = selectedStatus ? selectedStatus.id === status.id : false;
            const isDisabled =
              settings?.account?.mainCrm == CRM.DYNAMICS &&
              [
                OPPORTUNITY_STATUS_LOGIC_ROLE.CLOSED_LOST,
                OPPORTUNITY_STATUS_LOGIC_ROLE.CLOSED_WON,
              ].includes(status?.logicRole);
            const style = {
              backgroundColor: status.backgroundColor,
              borderColor: status.outlineColor,
              color: status.textColor,
            };
            const overrideStyle = isSelected ? { selectedStyle: style } : {};

            return (
              <Label
                value={status?.id}
                dataTest={status.logicRole}
                align="center"
                inline={false}
                key={`status-${status.name}`}
                selected={isSelected as boolean}
                hoverStyle={style}
                {...overrideStyle}
                disabled={isDisabled}
              >
                {status.name}
              </Label>
            );
          })}
        </RadioGroup>
      )}
    </div>
  );
}

function ReducedStatusManager() {
  const { t, send } = useStatusNoteActionContext();
  const showRelatedStatuses = false;

  return (
    <div className={styles._change_lead_status__wrapper}>
      <StatusSelector />
      <AdditionalInfoSelect />
      {showRelatedStatuses && (
        <Button
          variant="clear"
          className={styles._change_lead_status__button}
          iconLeft="refresh"
          uppercase={false}
          onClick={() => {
            send(EVENTS.DETAIL);
          }}
        >
          {t('updateRelatedStatus')}
        </Button>
      )}
    </div>
  );
}
