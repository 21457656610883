import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import '@babel/polyfill';
import { getAuthUrl } from '@bloobirds-it/utils';
import * as Sentry from '@sentry/react';
import { BrowserProfilingIntegration, BrowserTracing } from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import { createStore } from 'redux';

import App from './js/app';
import routerHistory from './js/app/history';
import { servicesEnv } from './js/misc/api/ApiHosts';
import SessionManagerFactory from './js/misc/session';
import reducer from './js/reducers';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

let enableDevTools = true;
if (window.location.hostname === 'app.bloobirds.com') {
  enableDevTools = false;
  mixpanel.init('b2373343acb028c8d63ce064fadcada2');
} else {
  mixpanel.init('tokenFake');
}

if (!isLocalhost) {
  Sentry.init({
    dsn: 'https://bdf7227780ad49d2bdb208b40ead4b88@o328732.ingest.sentry.io/1842685',
    environment: servicesEnv,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(routerHistory),
      }),
      // Add browser profiling integration to the list of integrations
      new BrowserProfilingIntegration(),
    ],
    release: `bloobirds@${process.env.REACT_APP_VERSION}`,
    tracesSampleRate: 1,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      'ResizeObserver loop limit exceeded',
      'A listener indicated an asynchronous response by returning true, but the message channel closed before a response was received',
      'Axios',
      'Request failed with status code',
      'Port is disconnected, cannot send message',
      'Attempt to register when device is in state "registered". Must be "unregistered".',
      /^Network Error$/,
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
}

const reduxDevTools =
  enableDevTools &&
  isLocalhost &&
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__({
    trace: true,
  });

console.info(`VERSION: ${process.env.REACT_APP_VERSION} - NODE ENV: ${process.env.NODE_ENV}`);
const SessionManager = SessionManagerFactory();

async function initializeApp() {
  if (tryLoginWithUrlToken()) {
    // Token was in the URL and processed
    renderApp();
  } else {
    // Try to get tokens via iframe
    try {
      await requestTokensFromAuth();
      console.log('Tokens received');
      if (!SessionManager.hasEmptySession()) {
        renderApp();
      } else if (
        window.location.pathname === '/terms-and-conditions' ||
        window.location.pathname === '/privacy-policy'
      ) {
        renderApp();
      } else {
        redirectToLogin();
      }
    } catch (error) {
      // if we are going to a public page (terms and conditions, privacy policy) we don't want to redirect to login
      if (
        window.location.pathname === '/terms-and-conditions' ||
        window.location.pathname === '/privacy-policy'
      ) {
        renderApp();
        return;
      }
      redirectToLogin();
    }
  }
}

function tryLoginWithUrlToken() {
  console.log('Attempting to login with token from URL');
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('bb-token');

  if (token) {
    SessionManager.setRootToken(token);
    localStorage.setItem('bb-token-webapp', token);
    localStorage.setItem('bb-token-extension', token);
    window.postMessage({ type: 'FROM_AUTH', token }, '*');
    console.log('Token saved from URL');
    // Remove the token from the URL
    window.history.replaceState({}, document.title, window.location.pathname);
    return true;
  }
  return false;
}

function requestTokensFromAuth() {
  return new Promise((resolve, reject) => {
    if (
      window.location.pathname === '/terms-and-conditions' ||
      window.location.pathname === '/privacy-policy'
    ) {
      resolve();
      return;
    }
    const authUrl = getAuthUrl();
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = `${authUrl}/tokenRelay`;
    document.body.appendChild(iframe);

    let tokenResponseTimeout;

    function handleMessage(event) {
      if (!authUrl.includes(event.origin)) {
        console.warn('Origin mismatch:', event.origin);
        return;
      }

      if (event.data.type === 'TOKEN_RESPONSE') {
        window.removeEventListener('message', handleMessage);
        document.body.removeChild(iframe);
        clearTimeout(tokenResponseTimeout);

        const { token, refreshToken, hashedFingerprint } = event.data;
        if (token && refreshToken && hashedFingerprint) {
          // Save the secured(new) tokens
          SessionManager.setRootToken(token);
          localStorage.setItem('bb-token', token);
          localStorage.setItem('bb-refresh-token', refreshToken);
          localStorage.setItem('bb-hashed-fingerprint', hashedFingerprint);
          resolve();
        } else if (token && !refreshToken && !hashedFingerprint) {
          // Save the old token
          SessionManager.setRootToken(token);
          localStorage.setItem('bb-token-webapp', token);
          resolve();
        } else {
          reject(new Error('No token received'));
        }
      }
    }

    window.addEventListener('message', handleMessage);

    iframe.onerror = () => {
      window.removeEventListener('message', handleMessage);
      document.body.removeChild(iframe);
      reject(new Error('Failed to load iframe'));
    };

    // Set a timeout in case TOKEN_RESPONSE is not received
    tokenResponseTimeout = window.setTimeout(() => {
      window.removeEventListener('message', handleMessage);
      document.body.removeChild(iframe);
      reject(new Error('Token response timed out'));
    }, 5000); // Adjust the timeout duration as needed
  });
}

function redirectToLogin() {
  window.location.href = getAuthUrl();
}

function renderApp() {
  const reduxDevTools =
    enableDevTools &&
    isLocalhost &&
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__({
      trace: true,
    });

  const store = reduxDevTools ? createStore(reducer, reduxDevTools) : createStore(reducer);

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root'),
  );
}

// Start the initialization process
initializeApp();
