import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Checkbox, Text } from '@bloobirds-it/flamingo-ui';

import { useRequestNumberFormContext } from '../../../context/requestedPhonesFormProvider';
import styles from '../../../styles/phoneNumberPage.module.css';

export const CapabilitiesCheckbox = () => {
  const { t } = useTranslation();
  const { phoneNumber, handleChangePhone } = useRequestNumberFormContext();

  return (
    <div className={styles.capabilitiesContainerPurchase}>
      <div className={styles.selectPhone}>
        <Checkbox
          size="small"
          onClick={() => {
            const newCapabilities = phoneNumber.capabilities.includes('voice')
              ? phoneNumber.capabilities.filter(capability => capability !== 'voice')
              : [...phoneNumber.capabilities, 'voice'];
            handleChangePhone('capabilities', newCapabilities);
          }}
          checked={phoneNumber.capabilities.includes('voice')}
        >
          {t(
            'accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.phoneNumberCapabilities.capabilities.voice',
          )}
        </Checkbox>
      </div>

      <div className={styles.textWithIcon}>
        <div className={styles.selectPhone}>
          <Checkbox
            size="small"
            onClick={() => {
              const newCapabilities = phoneNumber.capabilities.includes('sms')
                ? phoneNumber.capabilities.filter(capability => capability !== 'sms')
                : [...phoneNumber.capabilities, 'sms'];
              handleChangePhone('capabilities', newCapabilities);
            }}
            checked={phoneNumber.capabilities.includes('sms')}
          >
            {t(
              'accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.phoneNumberCapabilities.capabilities.sms',
            )}
          </Checkbox>
        </div>
        <Text size="s" color="peanut" weight="regular" className={styles.textSMS}>
          <Trans i18nKey="accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.phoneNumberCapabilities.smsInfo" />
        </Text>
      </div>
      <div className={styles.selectPhone}>
        <Checkbox
          size="small"
          onClick={() => {
            const newCapabilities = phoneNumber.capabilities.includes('whatsapp')
              ? phoneNumber.capabilities.filter(capability => capability !== 'whatsapp')
              : [...phoneNumber.capabilities, 'whatsapp'];
            handleChangePhone('capabilities', newCapabilities);
          }}
          checked={phoneNumber.capabilities.includes('whatsapp')}
        >
          {t(
            'accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.phoneNumberCapabilities.capabilities.whatsapp',
          )}
        </Checkbox>
      </div>
    </div>
  );
};
