export interface StatusObject {
  id: string;
  logicRole: string;
  ordering: number;
  value: string;
}

export enum COMPANY_STATUS {
  ACCOUNT = 'ACCOUNT',
  CONTACTED = 'CONTACTED',
  DISCARDED = 'DISCARDED',
  ENGAGED = 'ENGAGED',
  MEETING = 'MEETING',
  NURTURING = 'NURTURING',
  ON_PROSPECTION = 'ON_PROSPECTION',
}

export enum COMPANY_SALES_STATUS_VALUES_LOGIC_ROLE {
  ACTIVE = 'COMPANY__SALES_STATUS__ACTIVE',
  ON_HOLD = 'COMPANY__SALES_STATUS__ON_HOLD',
  NURTURING = 'COMPANY__SALES_STATUS__NURTURING',
  DISCARDED = 'COMPANY__SALES_STATUS__DISCARDED',
  CLIENT = 'COMPANY__SALES_STATUS__CLIENT',
}

export enum COMPANY_STATUS_LOGIC_ROLE {
  NEW = 'COMPANY__STATUS__NEW',
  BACKLOG = 'COMPANY__STATUS__BACKLOG',
  DELIVERED = 'COMPANY__STATUS__DELIVERED',
  ON_PROSPECTION = 'COMPANY__STATUS__ON_PROSPECTION',
  CONTACTED = 'COMPANY__STATUS__CONTACTED',
  ENGAGED = 'COMPANY__STATUS__ENGAGED',
  MEETING = 'COMPANY__STATUS__MEETING',
  NURTURING = 'COMPANY__STATUS__NURTURING',
  ON_HOLD = 'COMPANY__STATUS__ON_HOLD',
  DISCARDED = 'COMPANY__STATUS__DISCARDED',
  ACCOUNT = 'COMPANY__STATUS__ACCOUNT',
  CLIENT = 'COMPANY__STATUS__CLIENT',
  FINDING_LEADS = 'COMPANY__STATUS__FINDING_LEADS',
  READY_TO_PROSPECT = 'COMPANY__STATUS__READY_TO_PROSPECT',
}

export enum AVAILABLE_COMPANY_STATUS_LOGIC_ROLE {
  NEW = 'COMPANY__STATUS__NEW',
  BACKLOG = 'COMPANY__STATUS__BACKLOG',
  DELIVERED = 'COMPANY__STATUS__DELIVERED',
  ON_PROSPECTION = 'COMPANY__STATUS__ON_PROSPECTION',
  CONTACTED = 'COMPANY__STATUS__CONTACTED',
  ENGAGED = 'COMPANY__STATUS__ENGAGED',
  MEETING = 'COMPANY__STATUS__MEETING',
  NURTURING = 'COMPANY__STATUS__NURTURING',
  ON_HOLD = 'COMPANY__STATUS__ON_HOLD',
  DISCARDED = 'COMPANY__STATUS__DISCARDED',
  ACCOUNT = 'COMPANY__STATUS__ACCOUNT',
  CLIENT = 'COMPANY__STATUS__CLIENT',
}

export enum COMPANY_STAGE_LOGIC_ROLE {
  PROSPECT = 'COMPANY__STAGE__PROSPECTING',
  SALES = 'COMPANY__STAGE__SALES',
}

export enum COMPANY_HIGH_PRIORITY_LOGIC_ROLE {
  NO = 'COMPANY__HIGH_PRIORITY__NO',
  YES = 'COMPANY__HIGH_PRIORITY__YES',
}

export enum COMPANY_OPT_OUT_LOGIC_ROLE {
  NO = 'COMPANY__OPT_OUT__NO',
  YES = 'COMPANY__OPT_OUT__YES',
}

export enum COMPANY_FIELDS_LOGIC_ROLE {
  ASSIGNED_DATE = 'COMPANY__LAST_ASSIGNED_DATE',
  ASSIGNED_TO = 'COMPANY__ASSIGNED_TO',
  ATTEMPTS_COUNT = 'COMPANY__ATTEMPTS_COUNT',
  ATTEMPTS_LAST_DAY = 'COMPANY__ATTEMPTS_LAST_DAY',
  AUTHOR = 'COMPANY__AUTHOR',
  CADENCE_DATA = 'COMPANY__CADENCE_DATA',
  CADENCE_STOPPED = 'COMPANY__CADENCE_STOPPED',
  CADENCE = 'COMPANY__CADENCE',
  COMPANY_PARENT = 'COMPANY__COMPANY_PARENT',
  CONTAINS_INBOUND_LEAD = 'COMPANY__CONTAINS_INBOUND_LEAD',
  COUNTRY = 'COMPANY__COUNTRY',
  CREATION_DATETIME = 'COMPANY__CREATION_DATETIME',
  DISCARDED_REASONS = 'COMPANY__DISCARDED_REASONS',
  EMAIL = 'COMPANY__EMAIL',
  HAS_ATTEMPTS_AFTER_REASSIGN = 'COMPANY__HAS_ATTEMPTS_AFTER_REASSIGN',
  HIGH_PRIORITY = 'COMPANY__HIGH_PRIORITY',
  LINKEDIN_URL = 'COMPANY__LINKEDIN_URL',
  MR_RATING = 'COMPANY__MR_RATING',
  NAME = 'COMPANY__NAME',
  NUMBER_OF_LEADS = 'COMPANY__LEADS_COUNT',
  NURTURING_REASONS = 'COMPANY__NURTURING_REASONS',
  ON_HOLD_REASONS = 'COMPANY__ON_HOLD_REASONS',
  OPPORTUNITIES = 'COMPANY__OPPORTUNITIES',
  OPT_OUT = 'COMPANY__OPT_OUT',
  QQ_RATING = 'COMPANY__QQ_RATING',
  SALES_STATUS = 'COMPANY__SALES_STATUS',
  SALES_DISCARDED_REASONS = 'COMPANY__SALES_DISCARDED_REASONS',
  SALES_NURTURING_REASONS = 'COMPANY__SALES_NURTURING_REASONS',
  SALES_ON_HOLD_REASONS = 'COMPANY__SALES_ON_HOLD_REASONS',
  SCENARIO = 'COMPANY__SCENARIO',
  SOURCE = 'COMPANY__SOURCE',
  STAGE = 'COMPANY__STAGE',
  START_CADENCE = 'COMPANY__START_CADENCE',
  STATUS = 'COMPANY__STATUS',
  TARGET_MARKET = 'COMPANY__TARGET_MARKET',
  TIME_ZONE = 'COMPANY__TIME_ZONE',
  TOUCHES_COUNT = 'COMPANY__TOUCHES_COUNT',
  TOUCHES_LAST_DAY = 'COMPANY__TOUCHES_LAST_DAY',
  UPDATE_DATETIME = 'COMPANY__UPDATE_DATETIME',
  WEBSITE = 'COMPANY__WEBSITE',
  DATA_SOURCE_AUTOMATED = 'COMPANY__DATA_SOURCE_AUTOMATED',
  IS_INACTIVE = 'COMPANY__IS_INACTIVE',
  MAIN_NOTE = 'COMPANY__MAIN_NOTE',
  CADENCE_ENDED = 'COMPANY__CADENCE_ENDED',
  SALESFORCE_ACCOUNT_ID = 'SALESFORCE_ACCOUNT_ID',
  SALESFORCE_COMPANY_STATUS = 'SALESFORCE_COMPANY_STATUS',
  PERSON_CONTACT_ID = 'COMPANY__PERSON_CONTACT_ID',
  SALESFORCE_STATUS = 'SALESFORCE_COMPANY_STATUS',
  COPILOT_ANALYSIS = 'COMPANY__COPILOT_ANALYSIS',
}
