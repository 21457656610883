import React, { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ColorType,
  Dropdown,
  Icon,
  Item,
  useVisible,
  Text,
} from '@bloobirds-it/flamingo-ui';
import {
  Bobject,
  BobjectTypes,
  FIELDS_LOGIC_ROLE,
  LogicRoleType,
  OPPORTUNITY_FIELDS_LOGIC_ROLE,
} from '@bloobirds-it/types';
import {
  getMainBobjectIcon,
  getTextFromLogicRole,
  getValueFromLogicRole,
} from '@bloobirds-it/utils';
import { useOnClickOutside } from '@udecode/plate';

import { useStatusNoteActionContext } from '../../../hooks/useStatusNoteActions';
import styles from '../statusColumn.module.css';

export const StatusColumnBobjectSelector = ({
  bobjects,
}: {
  bobjects: Array<Bobject>;
  text: string;
}) => {
  const {
    bobject,
    machineContext,
    bobjectType,
    statusHasChangedState,
    formMethods,
    handleUpdateStatus,
    setLoading,
  } = useStatusNoteActionContext();
  const { control } = useFormContext();
  const {
    field: { ref: selectorRef, ...selectedOppController },
  } = useController({
    name: 'selectedBobjectId',
    control,
    defaultValue: machineContext?.selectedOpportunityObjecr?.id?.value,
  });
  const [statusHasChanged, setStatusHasChanged] = statusHasChangedState || [];
  const { t } = useTranslation();

  function getName(id: string) {
    const selectedBobject = bobjects.find(bobject => bobject?.id.value === id);
    return getTextFromLogicRole(selectedBobject, FIELDS_LOGIC_ROLE[bobjectType].NAME);
  }
  const { ref: dropdownRef, visible, setVisible } = useVisible();

  const saveOppStatus = e => {
    e.stopPropagation();
    handleUpdateStatus(formMethods.getValues()).finally(() => {
      setStatusHasChanged(false);
      setLoading(false);
    });
  };
  const ref = useOnClickOutside(() => {
    setVisible(false);
  });
  const iconProps = {
    name: getMainBobjectIcon((bobject || machineContext?.referenceBobject)?.id?.typeName),
    className: styles.headerBobjectIcon,
    color: 'softPeanut' as ColorType,
    size: 26,
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          cursor: 'pointer',
          justifyContent: 'space-between',
          gap: '4px',
        }}
        className={styles.dropdownAnchor}
      >
        <Icon {...iconProps} />
        <Dropdown
          visible={visible}
          ref={ref}
          anchor={
            <div
              onClick={() => {
                setVisible(!visible);
              }}
              style={{
                border: '1px solid var(--softPeanut)',
                borderRadius: '4px',
                padding: '3px 5px',
                display: 'flex',
                justifyContent: 'space-between',
                height: '24px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                flex: 'auto',
              }}
            >
              <Text size="xs" color="peanut">
                {getName(selectedOppController?.value)}
              </Text>
              <div style={{ alignSelf: 'flex-end', marginBottom: '-2px' }}>
                <Icon name="chevronDown" color="softPeanut" size={12} />
              </div>
            </div>
          }
          arrow={false}
          customStyles={{ width: '280px' }}
        >
          {
            <DropdownContents
              bobjects={bobjects}
              setVisible={setVisible}
              selectedOppController={selectedOppController}
            />
          }
        </Dropdown>
        <Button
          size="small"
          onClick={saveOppStatus}
          disabled={!statusHasChanged}
          className={styles.saveOppButton}
        >
          {t('common.save')}
        </Button>
      </div>
    </>
  );
};

const DropdownContents = ({ bobjects, setVisible, selectedOppController }) => {
  const {
    handleSelectedStatus: [, setSelectedStatus],
    availableStatuses,
    statusHasChangedState: [statusHasChanged, setStatusHasChanged],
    t,
  } = useStatusNoteActionContext();

  const [confirmationBanner, setConfirmationBanner] = useState(statusHasChanged);
  const { t: commonT } = useTranslation();
  function getName(id: string) {
    const selectedBobject = bobjects.find(bobject => bobject?.id.value === id);
    return getTextFromLogicRole(
      selectedBobject,
      OPPORTUNITY_FIELDS_LOGIC_ROLE.NAME as LogicRoleType<BobjectTypes.Opportunity>,
    );
  }

  return confirmationBanner ? (
    <div className={styles.confirmDeleteContainer}>
      <div className={styles.confirmDeleteText}>
        <Text size="s" weight="bold" color="peanut" align="center">
          {t('statusColumn.confirmChangeMessageTitle')}
        </Text>
        <Text size="s" color="peanut" align="center">
          {t('statusColumn.confirmChangeMessage')}
        </Text>
      </div>
      <div className={styles.confirmDeleteFooter}>
        <Button
          size="small"
          variant="clear"
          uppercase={false}
          onClick={e => {
            e.stopPropagation();
            setVisible(false);
            setConfirmationBanner(false);
          }}
        >
          <Text size="xs" color="bloobirds">
            {commonT('common.cancel')}
          </Text>
        </Button>
        <Button
          variant="primary"
          size="small"
          onClick={e => {
            e.stopPropagation();
            setStatusHasChanged(false);
            setVisible(true);
            setConfirmationBanner(false);
          }}
          color="bloobirds"
          uppercase={false}
        >
          <Text size="xs" color="white">
            {commonT('common.accept')}
          </Text>
        </Button>
      </div>
    </div>
  ) : (
    bobjects.map(bobject => {
      return (
        <Item
          key={bobject?.id.value}
          value={bobject?.id.value}
          onClick={value => {
            selectedOppController.onChange(value);
            setVisible(false);
            setSelectedStatus(
              availableStatuses.find(
                ({ id }) =>
                  id ===
                  getValueFromLogicRole(bobject, OPPORTUNITY_FIELDS_LOGIC_ROLE.STATUS as any),
              ),
            );
          }}
        >
          {getName(bobject?.id.value)}
        </Item>
      );
    })
  );
};
