import {
  Bobject,
  BobjectId,
  BobjectTypes,
  OPPORTUNITY_FIELDS_LOGIC_ROLE,
  SALESFORCE_LOGIC_ROLES,
} from '@bloobirds-it/types';
import { api, isContactSalesforce } from '@bloobirds-it/utils';

export const getSalesforceIdField = (
  bobject,
): { crmObject: string; crmId: SALESFORCE_LOGIC_ROLES } => {
  switch (bobject?.id?.typeName) {
    case BobjectTypes.Lead:
      return isContactSalesforce(bobject)
        ? { crmId: SALESFORCE_LOGIC_ROLES.CONTACT_ID_FIELD, crmObject: 'Contact' }
        : {
            crmId: SALESFORCE_LOGIC_ROLES.LEAD_ID_FIELD,
            crmObject: 'Lead',
          };
    case BobjectTypes.Company:
      return { crmId: SALESFORCE_LOGIC_ROLES.ACCOUNT_ID_FIELD, crmObject: 'Account' };
    case BobjectTypes.Opportunity:
      return { crmId: SALESFORCE_LOGIC_ROLES.OPPORTUNITY_ID_FIELD, crmObject: 'Opportunity' };
    default:
      return { crmId: undefined, crmObject: undefined };
  }
};

export const searchReferenceLeadBobject = async (phoneNumber: string) => {
  const response = await api.post('/calls/whiteLabel/search', {
    phoneNumber,
    includeTypes: ['LEAD'],
  });
  if (response?.status !== 200) {
    return;
  }
  const bobjects: Bobject[] = response?.data;
  if (bobjects.length === 0) {
    return;
  }
  return bobjects[0];
};
