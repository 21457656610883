import { useTranslation } from 'react-i18next';

import { InsightRenderer, useCopilotActivity, useGetCoreInsights } from '@bloobirds-it/copilot';
import { Icon, Text } from '@bloobirds-it/flamingo-ui';
import { ACTIVITY_FIELDS_LOGIC_ROLE, Bobject } from '@bloobirds-it/types';
import { getFieldByLogicRole } from '@bloobirds-it/utils';

import styles from './insights.module.css';
import NoResultsFound from './noResultsFound';

interface Props {
  activity: Bobject;
}

const Insights = ({ activity }: Props) => {
  const { t } = useTranslation();
  const { activityInsights: data } = useCopilotActivity();
  const type = getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE);
  const mainTypeField = getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_MAIN_TYPE);
  const coreInsights = useGetCoreInsights({ type, mainTypeField });

  return (
    <div className={styles.insights}>
      <div className={styles.insightsTitle}>
        <div className={styles.titleSection}>
          <Icon name="snippet" color="purple" />
          <Text size="s" weight="heavy">
            {t('ai.aiAnalysis.insightsFor')}
          </Text>
        </div>
      </div>

      {coreInsights && data?.insights?.length > 0 ? (
        data?.insights?.map(i => {
          return (
            <InsightRenderer
              key={i.pk}
              bobjectId={activity?.id}
              insight={i}
              insightsList={[...coreInsights.values()]}
            />
          );
        })
      ) : (
        <NoResultsFound />
      )}
    </div>
  );
};

export default Insights;
