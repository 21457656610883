import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createToast, IconButton } from '@bloobirds-it/flamingo-ui';
import { useCustomTasks, usePermissions, useThirdPartyAnalytics } from '@bloobirds-it/hooks';
import { getI18nSpacetimeLng } from '@bloobirds-it/internationalization';
import { SMSTemplateSelector } from '@bloobirds-it/playbook';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  Bobject,
  BobjectId,
  BobjectTypes,
  DataModelResponse,
  LEAD_FIELDS_LOGIC_ROLE,
  MessagesEvents,
  MIXPANEL_EVENTS,
  SentryMessages,
  SentryTags,
  UserPermission,
} from '@bloobirds-it/types';
import {
  api,
  getFieldByLogicRole,
  getReferencedBobject,
  getUserTimeZone,
  getValueFromLogicRole,
} from '@bloobirds-it/utils';
import clsx from 'clsx';
import mixpanel from 'mixpanel-browser';
import spacetime from 'spacetime';
import useSWR from 'swr';

import { DetailsFooter } from '../../components/detailsFooter/detailsFooter';
import AutoresizingTextArea from './AutoresizingTextArea';
import styles from './smsDetail.module.css';
import { SmsDetailedActivity } from './smsDetailActivity';

export interface DetailsActivityProps {
  aggregatedConversations: Bobject[];
  items: any[];
  totalMatching: number;
  fetchNextPage: () => void;
  isLoading: boolean;
}

export interface SmsMessage {
  caseId?: any;
  caseNumber?: any;
  id: string;
  wid: string;
  direction: 'INCOMING' | 'OUTGOING';
  body: string;
  isoDate: string;
  user: string;
}

export interface GroupedMessages {
  formattedDate: string;
  messages: SmsMessage[];
}
export interface MessagesPerDay {
  [formattedDate: string]: GroupedMessages;
}

export interface SmsMessages {
  messages: MessagesPerDay;
  isLoading: boolean;
  totalMatching: number;
  fetchNextPage: () => void;
}

function getISODate(date) {
  const sDate = spacetime(date);
  return sDate.format('{year}-{iso-month}-{date-pad}');
}

async function fetchSMSMessages(accountId, leadId, customTaskId) {
  const { data } = await api.post(`/bobjects/${accountId}/Activity/search`, {
    query: {
      ACTIVITY__LEAD: [leadId],
      ACTIVITY__TYPE: ['ACTIVITY__TYPE__CUSTOM_TASK'],
      ACTIVITY__CUSTOM_TASK: customTaskId,
    },
    formFields: true,
    pageSize: 200,
    injectReferences: true,
  });
  return data;
}

function formatDate(date, lang, timezone, t): string {
  const now = getI18nSpacetimeLng(lang, new Date(), timezone);
  const sDate = getI18nSpacetimeLng(lang, date, timezone);

  if (sDate.isSame('day', now)) {
    return t('whatsapp.chat.today');
  } else if (sDate.isSame('day', now.subtract(1, 'day'))) {
    return t('whatsapp.chat.yesterday');
  } else if (sDate.isSame('year', now)) {
    const format = lang === 'es' ? '{date} {month-short}' : '{month-short} {date-ordinal}';
    return sDate.format(format);
  } else {
    const format =
      lang === 'es' ? '{date} {month-short}, {year}' : '{month-short} {date-ordinal}, {year}';
    return sDate.format(format);
  }
}

function parseMessages(smsMessages, lang, t): MessagesPerDay {
  const timeZone = getUserTimeZone();
  const parsedMessages =
    smsMessages?.contents.map(message => {
      const media = getValueFromLogicRole(message, 'ACTIVITY__ATTACHMENTS');
      return {
        id: message.id.value,
        wid: getValueFromLogicRole(message, ACTIVITY_FIELDS_LOGIC_ROLE.WHATSAPP_ID),
        direction:
          getFieldByLogicRole(message, ACTIVITY_FIELDS_LOGIC_ROLE.DIRECTION)?.valueLogicRole ===
          'ACTIVITY__DIRECTION__INCOMING'
            ? 'INCOMING'
            : 'OUTGOING',
        body: getValueFromLogicRole(message, ACTIVITY_FIELDS_LOGIC_ROLE.MESSAGE_BODY),
        media: media ? JSON.parse(media) : null,
        isoDate: getValueFromLogicRole(message, ACTIVITY_FIELDS_LOGIC_ROLE.TIME),
        user: getValueFromLogicRole(message, ACTIVITY_FIELDS_LOGIC_ROLE.USER, true),
      };
    }) || [];

  // Then, group them by day
  const groupedByDay: MessagesPerDay = {};
  for (const message of parsedMessages) {
    const isoKey = getISODate(message.isoDate);
    const formattedDateKey = formatDate(message.isoDate, lang, timeZone, t);

    if (!groupedByDay[isoKey]) {
      groupedByDay[isoKey] = {
        formattedDate: formattedDateKey,
        messages: [],
      };
    }
    groupedByDay[isoKey].messages.push(message);
  }

  // Sort the messages by date
  for (const key of Object.keys(groupedByDay)) {
    const messages = groupedByDay[key].messages;
    groupedByDay[key].messages = messages.sort((a, b) => {
      const dateA = spacetime(a.isoDate);
      const dateB = spacetime(b.isoDate);
      return dateA.isBefore(dateB) ? 1 : -1;
    });
  }

  // Sort the keys by date
  return Object.fromEntries(
    Object.entries(groupedByDay).sort((a, b) => {
      const dateA = spacetime(a[0]);
      const dateB = spacetime(b[0]);
      return dateA.isBefore(dateB) ? 1 : -1;
    }),
  );
}

const useSMSMessages = (leadId: string, accountId: string, subscribeListeners): SmsMessages => {
  const { getCustomTaskByLogicRole } = useCustomTasks();
  const { i18n, t } = useTranslation();
  const smsCustomTask = getCustomTaskByLogicRole('SMS');
  const lang = i18n.language;

  const { data: smsMessages, mutate } = useSWR([accountId, leadId, smsCustomTask?.id], () =>
    fetchSMSMessages(accountId, leadId, smsCustomTask?.id),
  );

  const messages = parseMessages(smsMessages, lang, t);

  function fetchNextPage() {
    mutate();
  }

  subscribeListeners?.(BobjectTypes.Activity, mutate);

  const isLoading = !smsMessages;
  const totalMatching = smsMessages?.totalMatching;

  return { messages, isLoading, totalMatching, fetchNextPage };
};

export const SmsDetail = ({
  settings,
  activity,
  accountId,
  dataModel,
  activeBobject,
  userId,
  subscribeListeners,
  phoneTo,
}: {
  settings: any;
  activity: Bobject;
  activeBobject: Bobject;
  accountId: string;
  dataModel: DataModelResponse;
  visibleFooter?: boolean;
  actionsDisabled?: boolean;
  userId?: string;
  subscribeListeners: (type: BobjectTypes, mutator: () => void) => void;
  phoneTo?: string;
}) => {
  const permissions = usePermissions(settings?.user?.permissions);
  const canHandleSMS = permissions.get(UserPermission.SMS_CHANNEL);
  const { sendThirdPartyError } = useThirdPartyAnalytics();

  const leadId = activity
    ? getValueFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.LEAD)
    : activeBobject?.id?.typeName === BobjectTypes.Lead
      ? activeBobject?.id?.value
      : null;
  const companyId = activity
    ? getValueFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.COMPANY)
    : activeBobject?.id?.typeName === BobjectTypes.Company
      ? activeBobject?.id?.value
      : null;

  const hasSmsPermission = settings?.user?.permissions?.includes(
    UserPermission.WHATSAPP_BUSINESS_ADMIN,
  );

  const { messages, isLoading, totalMatching, fetchNextPage } = useSMSMessages(
    leadId,
    accountId,
    subscribeListeners,
  );
  const { t } = useTranslation();
  const messageToSend = useRef('');
  const [isSending, setIsSending] = useState(false);
  const [defaultMessage, setDefaultMessage] = useState('');
  const [resetMessageCounter, setMessageCounter] = useState(0);

  const referenceBobject = getReferencedBobject(activity);
  const assignee =
    getFieldByLogicRole(
      referenceBobject,
      LEAD_FIELDS_LOGIC_ROLE.ASSIGNED_TO,
      // @ts-ignore
    )?.value || referenceBobject?.assignedTo;

  const assignedToActiveUser = assignee === userId;
  const phoneToValue =
    phoneTo || getValueFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.CALL_LEAD_PHONE_NUMBER);
  const phoneFrom = getValueFromLogicRole(
    activity,
    ACTIVITY_FIELDS_LOGIC_ROLE.CALL_USER_PHONE_NUMBER,
  );

  useEffect(() => {
    fetchNextPage();
  }, []);

  const sendSmsMessage = () => {
    mixpanel.track(MIXPANEL_EVENTS.SEND_SMS_MESSAGE);
    if (messageToSend.current != '') {
      setIsSending(true);
      api
        .post('/messaging/sms/send', {
          to: phoneToValue,
          body: messageToSend.current,
          leadId: leadId,
          companyId: companyId,
          markConversationAsReported: true,
        })
        .then(() => {
          setDefaultMessage('');
          setMessageCounter(c => c + 1);
          messageToSend.current = '';
          mixpanel.track(MIXPANEL_EVENTS.SEND_SMS_FROM_BB_SUCCESS);

          setIsSending(false);
          setTimeout(() => {
            fetchNextPage();
            window.dispatchEvent(
              new CustomEvent(MessagesEvents.ActiveBobjectUpdated, {
                detail: { type: BobjectTypes.Activity },
              }),
            );
          }, 1500);
        })
        .catch(error => {
          sendThirdPartyError(
            { key: MIXPANEL_EVENTS.SEND_SMS_FROM_BB_ERROR },
            { sentryTag: SentryTags.SMSError, sentryMessage: SentryMessages.SMSError },
            {
              error,
              extra: {
                Message: JSON.stringify(messageToSend.current || {}),
              },
            },
          );

          createToast({
            message: t('sms.errorSending'),
            type: 'error',
          });
          setIsSending(false);
        });
    }
  };

  const hasPermissionToSend =
    hasSmsPermission || (assignedToActiveUser && phoneFrom && phoneTo) || true;

  const whatsappTemplatesClasses = clsx(styles.whatsAppContainer, {
    [styles.whatsAppContainerDisabled]: !hasPermissionToSend || isSending,
  });

  return (
    <div className={styles.container}>
      <div className={styles.activity_container}>
        <SmsDetailedActivity
          activity={activity}
          dataModel={dataModel}
          messagesPerDay={messages}
          isLoading={isLoading}
          totalMatching={totalMatching}
          fetchNextPage={fetchNextPage}
          activeBobject={activeBobject}
        />
      </div>
      {canHandleSMS && (
        <>
          <DetailsFooter color="lightestPurple" withPadding={false}>
            <div className={styles.chatBox}>
              <SMSTemplateSelector
                leadId={leadId as BobjectId['value']}
                companyId={companyId as BobjectId['value']}
                setMessage={message => {
                  const templateWithoutHtml = message
                    .replace(/<(?:br|\/div|\/p)>/g, '\n')
                    .replace(/<.*?>/g, '');
                  setDefaultMessage(templateWithoutHtml);
                  messageToSend.current = templateWithoutHtml;
                }}
                anchorProps={{
                  className: whatsappTemplatesClasses,
                  color: 'bloobirds',
                }}
              />
              <AutoresizingTextArea
                hasPermissionToSend={hasPermissionToSend}
                isSending={isSending}
                defaultMessage={defaultMessage}
                setMessage={message => {
                  setDefaultMessage(message);
                  messageToSend.current = message;
                }}
                resetMessageCounter={resetMessageCounter}
              />
              <IconButton
                name="deliver"
                disabled={isSending || !hasPermissionToSend}
                color="bloobirds"
                size={24}
                onClick={sendSmsMessage}
              />
            </div>
          </DetailsFooter>
        </>
      )}
    </div>
  );
};
