import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, IconButton, Input, Text, Tooltip } from '@bloobirds-it/flamingo-ui';
import { useEmailConnections } from '@bloobirds-it/hooks';
import { NylasAlias } from '@bloobirds-it/types';

import { useHover } from '../../../hooks';
import { isEmail } from '../../../misc/utils';
import styles from './aliasName.module.css';

export const AliasName = ({ alias }: { alias: NylasAlias }) => {
  const [isEdition, setIsEdition] = useState<boolean>();
  const [emailAlias, setEmailAlias] = useState<string>(alias?.emailAlias);
  const [ref, isHover] = useHover();
  const { removeAlias, updateAlias, updateDefaultAliasConnection } = useEmailConnections();
  const [error, setError] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (emailAlias?.length > 0 && !isEmail(emailAlias)) {
      setError(true);
    } else {
      setError(false);
    }
  }, [emailAlias]);

  const handleUpdateAlias = () => {
    updateAlias(alias.id, {
      emailAlias: emailAlias,
    });
    setIsEdition(false);
  };

  const handleRemoveAlias = () => {
    removeAlias(alias.id);
  };

  return (
    <div ref={ref as any} className={styles.container}>
      {isEdition ? (
        <span>
          <Input
            value={emailAlias}
            onChange={setEmailAlias}
            size="small"
            error={error && t('validation.email')}
            width="200px"
          />
          <IconButton name="check" size={16} onClick={handleUpdateAlias} />
        </span>
      ) : (
        <span>
          <Text key={alias?.id} size="xs">
            {alias?.emailAlias}
          </Text>
          {isHover && (
            <>
              {!isEdition && (
                <IconButton name="edit" size={18} onClick={() => setIsEdition(true)} />
              )}
              <IconButton name="cross" color="tomato" size={18} onClick={handleRemoveAlias} />
            </>
          )}
        </span>
      )}
      {alias?.default && (
        <IconButton
          name={alias?.default ? 'starChecked' : 'starUnchecked'}
          color="banana"
          size={18}
          onClick={() => updateDefaultAliasConnection(alias?.emailAlias)}
        />
      )}
      {isHover && !alias?.default && (
        <Tooltip title={t('userSettingsWebapp.emailSettings.setAsDefault')} position="top">
          <IconButton
            name="starUnchecked"
            color="banana"
            size={18}
            onClick={() => updateDefaultAliasConnection(alias?.emailAlias)}
          />
        </Tooltip>
      )}
    </div>
  );
};
