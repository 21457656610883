import React, { useEffect } from 'react';

import { MIXPANEL_EVENTS } from '@bloobirds-it/types';
import mixpanel from 'mixpanel-browser';

import { OTOConnectionsGuide } from '../../quickStartGuideBlocks/otoConnectionsGuide';
import styles from './otoSlides.module.css';

function GuideScreenOTO({
  handleFinish,
  setHideFooter,
}: {
  handleFinish: () => void;
  setHideFooter: (value: boolean) => void;
}) {
  useEffect(() => {
    setHideFooter(true);
    mixpanel.track(MIXPANEL_EVENTS.DISPLAY_QUICK_START_GUIDE_WELCOME_SCREEN_OTO);
  }, []);

  return (
    <div key="guideScreenOTO" className={styles.backgroundWhite}>
      <OTOConnectionsGuide handleFinish={handleFinish} />
    </div>
  );
}

export default GuideScreenOTO;
